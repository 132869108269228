import React from 'react';
import { Link } from 'react-router';
import { isEmpty } from 'lodash';
import classNames from 'classnames';

import ConnectPlatforms from '../connect-platforms/ConnectPlatforms';
import { simpleNotification } from '../../utils/notifications';
import Logo from '../../components/logo/Logo';
import PromptDialog from '../dialog/PromptDialog';
import './ConnectAccounts.scss';

class ConnectAccounts extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      originalPath: null,      // 用于存储最初的来源页面
      isLoading: false,        // 控制是否显示加载动画
      showSuccessDialog: false, // 控制是否显示成功对话框
      showErrorDialog: false,   // 控制是否显示错误对话框
      errorMessage: '',         // 存储错误信息
      successPlatform: '',      // 存储成功连接的平台名称
      specialRedirect: null     // 存储特殊重定向路径
    };

    // 保存进入页面前的路径，用于后续返回
    if (!localStorage.getItem('previousPath') && this.props.router && this.props.router.location) {
      const referrer = document.referrer;
      console.log('Constructor - Document referrer:', referrer);
      console.log('Constructor - Current location:', window.location.href);
      // 只有当前来自内部页面才记录前一路径
      if (referrer && (referrer.includes(window.location.hostname) || referrer.includes('localhost'))) {
        try {
          const url = new URL(referrer);
          const path = url.pathname + url.search;
          if (path && !path.includes('connect-accounts')) {
            localStorage.setItem('previousPath', path);
            console.log('Saved previous path:', path);
          }
        } catch (e) {
          console.error('Error parsing referrer:', e);
        }
      }
    } else if (localStorage.getItem('previousPath')) {
      // 如果已经有保存的路径，读取它
      this.state.originalPath = localStorage.getItem('previousPath');
      console.log('Found existing previous path:', this.state.originalPath);
    }
  }

  UNSAFE_componentWillMount() {
    const { token } = this.props.auth;
    // if (user.role === 'brand') {
    //   this.props.router.replace('/portal');
    // }
    if (
      this.props.router.location.pathname === '/connect-accounts/twitch' &&
      !isEmpty(this.props.router.location.query.code) &&
      !isEmpty(this.props.router.location.query.scope)
    ) {
      // 设置加载状态
      this.setState({ isLoading: true });
      // Requesting a JWT token with the oauth callback code.
      const { code, scope } = this.props.router.location.query;
      this.props.loginWithTwitchOAuthCode(code, scope, token);
    }
    if (
      this.props.router.location.pathname === '/connect-accounts/twitter' &&
      !isEmpty(this.props.router.location.query.oauth_token) &&
      !isEmpty(this.props.router.location.query.oauth_verifier)
    ) {
      // 设置加载状态
      this.setState({ isLoading: true });
      const { oauth_token, oauth_verifier } = this.props.router.location.query;
      this.props.loginWithTwitterOAuthCode(oauth_token, oauth_verifier, token);
    }
    if (
      this.props.router.location.pathname === '/connect-accounts/youtube' &&
      !isEmpty(this.props.router.location.query.code) &&
      !isEmpty(this.props.router.location.query.scope)
    ) {
      // 设置加载状态
      this.setState({ isLoading: true });
      const { code, scope } = this.props.router.location.query;
      this.props.loginWithYoutubeOAuthCode(code, scope, token);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.auth.youtube.isLoading === true && nextProps.auth.youtube.isLoading === false) {
      // 停止显示加载动画
      this.setState({ isLoading: false });
      if (nextProps.auth.youtube.errors.hasError) {
        // 改为显示错误对话框
        this.setState({
          showErrorDialog: true,
          errorMessage: nextProps.auth.youtube.errors.message
        });
        // 移除查询参数但保留在当前页面
        this.props.router.push('/connect-accounts');
      } else {
        // 认证成功，显示成功对话框
        this.props.router.push('/connect-accounts');
        
        this.setState({
          showSuccessDialog: true,
          successPlatform: 'YouTube'
        });
        
        // 特殊情况处理
        if (localStorage.getItem('backToBooth') === 'true') {
          localStorage.removeItem('backToBooth');
          this.setState({ specialRedirect: '/make-some-noiz?connected=true' });
        }
      }
    }
    if (this.props.auth.youtube.isDisconnecting === true && nextProps.auth.youtube.isDisconnecting === false) {
      if (nextProps.auth.youtube.errors.hasError) {
        simpleNotification({
          level: 'error',
          title: 'Youtube disconnect error',
          message: nextProps.auth.youtube.errors.message,
          autoDismiss: 0,
          position: 'tl',
        });
      } else {
        simpleNotification({
          level: 'success',
          title: 'Success',
          message: 'Twitter account connected successfully',
          autoDismiss: 3,
          position: 'tl',
        });
        // 移除查询参数但保留在当前页面
        this.props.router.replace('/connect-accounts');
      }
    }
    if (this.props.auth.twitter.isLoading === true && nextProps.auth.twitter.isLoading === false) {

      // 停止显示加载动画
      this.setState({ isLoading: false });
      if (nextProps.auth.twitter.errors.hasError) {
        // 改为显示错误对话框
        this.setState({
          showErrorDialog: true,
          errorMessage: nextProps.auth.twitter.errors.message
        });

        // 移除查询参数但保留在当前页面
        this.props.router.push('/connect-accounts');
      } else {
        // 认证成功，显示成功对话框
        this.props.router.push('/connect-accounts');

        this.setState({
          showSuccessDialog: true,
          successPlatform: 'Twitter'
        });

        // 特殊情况处理
        if (localStorage.getItem('backToBooth') === 'true') {
          localStorage.removeItem('backToBooth');
          this.setState({ specialRedirect: '/make-some-noiz?connected=true' });
        }
      }
    }

    // Updating things when twitch finish the request loading.
    if (this.props.auth.twitch.isLoading === true && nextProps.auth.twitch.isLoading === false) {
      this.props.validateUserHandshake(this.props.auth.token, '');

      // 停止显示加载动画
      this.setState({ isLoading: false });

      if (nextProps.auth.twitch.errors.hasError) {
        // 改为显示错误对话框
        this.setState({
          showErrorDialog: true,
          errorMessage: nextProps.auth.twitch.errors.message
        });
        this.props.router.replace('/connect-accounts');

        // 特殊情况处理
        if (localStorage.getItem('backToBooth') === 'true') {
          localStorage.removeItem('backToBooth');
          // 不直接跳转，通过对话框关闭后处理
          this.setState({ specialRedirect: '/make-some-noiz?connected=true' });
        }
      } else {
        // 不再显示简单通知，而是显示对话框
        // 移除查询参数但保留在当前页面
        this.props.router.push('/connect-accounts');

        // 显示成功对话框
        // Twitch 认证成功处理
        this.setState({
          showSuccessDialog: true,
          successPlatform: 'Twitch'  // 添加这一行
        });

        // 特殊情况处理
        if (localStorage.getItem('backToBooth') === 'true') {
          localStorage.removeItem('backToBooth');
          // 不直接跳转，通过对话框关闭后处理
          this.setState({ specialRedirect: '/make-some-noiz?connected=true' });
        }
      }
    }
  }

  // 处理成功对话框关闭
  handleSuccessDialogClose = () => {
    console.log('Success dialog closed');
    this.setState({ showSuccessDialog: false });

    // 如果有特殊重定向，使用它
    if (this.state.specialRedirect) {
      this.props.router.push(this.state.specialRedirect);
      return;
    }

    // 否则执行正常的返回逻辑
    this.goBack();
  }

  goBack = () => {
    // 添加更多日志帮助调试
    console.log('goBack function called');
    console.log('Current location:', window.location.href);
    console.log('Router location:', this.props.router.location);
    // 首先检查是否有保存的前一页面路径
    const previousPath = localStorage.getItem('previousPath');
    console.log('previousPath from localStorage:', previousPath);
    console.log('originalPath from state:', this.state.originalPath);
    // 优先使用 localStorage 中的路径，其次使用组件状态中的路径
    const pathToUse = previousPath || this.state.originalPath;

    if (pathToUse) {
      console.log('Using saved path for navigation:', pathToUse);
      if (previousPath) localStorage.removeItem('previousPath');
      this.props.router.push(pathToUse);
      return;
    }

    // 优化返回逻辑，不再依赖 document.referrer
    try {
      // 检查是否有历史记录可以返回
      if (this.props.router && this.props.router.location && this.props.router.location.key) {
        // 如果有历史记录，尝试返回上一页
        console.log('Attempting to go back using router history');
        this.props.router.goBack();

        // 给返回操作设置一个超时，如果没有成功返回，则导航到首页
        setTimeout(() => {
          // 如果当前路径仍然是 connect-accounts 相关，说明返回失败，需要重定向到首页
          if (this.props.router.location.pathname.includes('connect-accounts')) {
            console.log('Go back failed or stayed on connect-accounts, redirecting to home');
            this.props.router.replace('/');
          }
        }, 100);
      } else {
        // 没有历史记录，直接返回首页
        console.log('No router history key found, redirecting to home');
        this.props.router.replace('/');
      }
    } catch (error) {
      // 出现任何错误，都返回首页
      console.error('Error during navigation:', error);
      this.props.router.replace('/');
    }
  }

  displayNotConnectedMessage = () => {
    simpleNotification({
      level: 'warning',
      title: '',
      message: 'You must connect at least one streaming platform in order to see available quests.',
    });
  }

  // 添加错误对话框处理方法
  handleErrorDialogClose = () => {
    console.log('Error dialog closed');
    this.setState({ showErrorDialog: false });

    // 关闭错误对话框后，保留在当前页面
    // 不执行goBack，让用户可以重试
  }

  render() {
    const { user } = this.props.auth;

    const { isLoading, showSuccessDialog, showErrorDialog, errorMessage, successPlatform } = this.state;

    // 如果正在加载，显示加载动画
    if (isLoading) {
      return (
        <section className="ConnectAccounts loading-container">
          <div className="loading-spinner">
            <i className="fa fa-spinner fa-spin fa-3x fa-fw" />
            <span>Connecting account, please wait...</span>
          </div>
        </section>
      );
    }

    return (
      <section className="ConnectAccounts">
        {showSuccessDialog && (
          <PromptDialog
            dialogOpen={true}
            level="success"
            title="Connection Successful!"
            content={`Your ${successPlatform || 'Twitch'} account has been successfully connected`}
            cancelAction={this.handleSuccessDialogClose}
            cancelButtonName="Close"
          />
        )}
        {/* 错误对话框 */}
        {showErrorDialog && (
          <PromptDialog
            dialogOpen={true}
            level="failure"
            title="Connection Failed"
            content={errorMessage || 'Could not connect your account. Please try again.'}
            cancelAction={this.handleErrorDialogClose}
            cancelButtonName="Close"
          />
        )}
        <section className="ModalMaster">
          <section className={classNames('Modal Modal__welcome active')}>
            <div
              className="Modal__layer"
              onClick={(e) => {
                if (!(user.connectedAccounts.twitch.id || user.connectedAccounts.twitch.username)) {
                  e.preventDefault();
                } else {
                  this.goBack();
                }
              }}
              role="button"
              aria-hidden
            />
            <section className="Modal__wrapper">
              <div className="Modal__icon">
                <Logo />
                {(user.connectedAccounts.twitch.id || user.connectedAccounts.twitch.username) &&
                  <button
                    className="Modal__close-btn"
                    onClick={this.goBack}
                    aria-hidden
                  />
                }
              </div>
              <div className="Welcome__header">
                Welcome
              </div>
              <ConnectPlatforms
                auth={this.props.auth}
                config={this.props.config}
                disconnectTwitch={this.props.disconnectTwitch}
                disconnectYoutube={this.props.disconnectYoutube}
              />
              <div className="Welcome__footer">
                <button onClick={this.goBack}>Close</button>
                <Link className="logout" to="/logout" href="/logout">Logout</Link>
              </div>
              <div className="blurb">
                Designed by game developers and gamers, for game developers and gamers
              </div>
            </section>
          </section>
        </section>
      </section>
    );
  }
}

export default ConnectAccounts;
