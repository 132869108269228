import React from 'react';
import Raven from 'raven-js';
import classNames from 'classnames';
import { Link } from 'react-router';
import SignInForm from '../../forms/SignInForm';
import '../login.scss';
import './SignIn.scss';
import { throttle } from '../../../utils/functions';
import Logo from '../../logo/Logo';
import { pullRedirectParamAndRedirect } from '../../../utils/util';
import UpdateModel from './UpdateModel';


class SignIn extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.throtSignIn = throttle(this.props.signIn, 3000);
    this.throtSignInBefore =  throttle(this.props.signInBefore, 3000);
    this.loginInfo = {};
    this.state = {
      showingErrors: false,
      errors: {
        hasError: false,
        code: '',
        message: '',
        all: {},
      },
      showUpdatePolicy: false,
      acceptedPolicy: false,
      isClicked: false,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.auth.isLoading === true && nextProps.auth.isLoading === false) {
      // // When login fails or user is trying to hack/test out to date OAuth Code in query string:
      if (nextProps.auth.errors.hasError) {
        this.setState({ showingErrors: true, errors: nextProps.auth.errors, isClicked: false });
      } else if (nextProps.auth?.isReadUpdatePolicy === false && this.state.acceptedPolicy === false) {
        this.setState({ showUpdatePolicy: true });
      } else if (nextProps.auth?.isReadUpdatePolicy === true && this.state.isClicked === false) {
        this.throtSignIn(this.loginInfo);
        this.setState({ isClicked: true })
      } else if (this.props.auth.isAuthenticated !== nextProps.auth.isAuthenticated) {
        Raven.setUserContext({
          email: nextProps.auth.user.email,
          id: nextProps.auth.user.id,
          username: nextProps.auth.user.username,
          role: nextProps.auth.user.role,
          status: nextProps.auth.user.status,
        });

        Raven.captureMessage('Login', {
          level: 'info', // one of 'info', 'warning', or 'error'
        });
        // Getting the initial info with the obtained token.
        const { token } = nextProps.auth;
        this.props.getInitialInfo(token);
        // Redirecting user to the game list
        // firstname, lastname, birthday, country
        if (
          nextProps.auth.user.firstName !== '' &&
          nextProps.auth.user.lastName !== '' &&
          nextProps.auth.user.timezone !== '' &&
          nextProps.auth.user.birthDate !== '' &&
          nextProps.auth.user.gender !== '' &&
          nextProps.auth.user.country !== ''
        ) {
          if (this.props.router?.location?.query?.redirectto) {
            pullRedirectParamAndRedirect(this.props.router);
          } else {
            if (nextProps.auth.user.isCheckNewVersion) {
              this.props.router.replace('/quests');
            } else {
              this.props.router.replace('/about-us-beta3');
              const { user, token } = this.props.auth;
              this.props.updateInfoByUser({ ...user, isCheckNewVersion: true }, token);
            }
          }
        } else {
          this.props.router.replace('/complete-registration');
        }
      }
    }
  }

  dismissErrors = () => {
    this.setState({
      showingErrors: false,
      errors: {
        hasError: false,
        code: '',
        message: '',
        all: {},
      },
    });
  }
  onSubmit(payload) {
    this.loginInfo = payload;
    this.throtSignInBefore(payload);
  }

  handleSubmit = () => {
    this.setState({ showUpdatePolicy: false })
    this.throtSignIn({ ...this.loginInfo, isReadUpdatePolicy: false });
  }

  handleAcceptedChange = (value) => {
    this.setState({ acceptedPolicy: value });
  }

  render() {
    const { showingErrors, errors, showUpdatePolicy, acceptedPolicy } = this.state;
    return (
      <section className="SignIn LogIn">
        <section className="ModalMaster">
          <section className={classNames('Modal active')}>
            <Link to="/" href="/" className="Modal__layer" role="button" />
            <section className="Modal__wrapper1">
              <Link
                className="Modal__close-btn"
                to="/"
                href="/"
                role="button"
              />
              <div className="Modal__left1">
                <Logo />
              </div>
              <div className="Modal__right">
                <div className="Modal__content">
                  <ul className="Modal__tabs">
                    <li>
                      <Link
                        className="Modal__tab"
                        to="/sign-up"
                        href="/sign-up"
                        role="button"
                        activeClassName="active"
                      >
                        SIGN UP
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="Modal__tab"
                        to="/sign-in"
                        href="/sign-in"
                        role="button"
                        activeClassName="active"
                      >
                        SIGN IN
                      </Link>
                    </li>
                  </ul>
                  <SignInForm auth={this.props.auth} onSubmit={this.onSubmit.bind(this)} />
                  <section className={classNames('errors-container', { active: showingErrors })}>
                    <div className="error-message1">
                      {errors.message}
                    </div>
                    <button className="error-dismiss" type="button" onClick={this.dismissErrors.bind(this)} />
                  </section>
                </div>
              </div>
              <div className="blurb">
                Designed by game developers and gamers, for game developers and gamers
              </div>
            </section>
          </section>
        </section>
        <UpdateModel
          closeModal={() => this.setState({ showUpdatePolicy: false, acceptedPolicy: false })}
          open={showUpdatePolicy}
          onSubmit={this.handleSubmit}
          accepted={acceptedPolicy}
          handleAcceptedChange={this.handleAcceptedChange}
        />
      </section>
    );
  }
}

SignIn.propTypes = {
  // router: PropTypes.shape({
  //   replace: PropTypes.func.isRequired,
  // }).isRequired,
  // logout: PropTypes.func.isRequired,
};

export default SignIn;
