/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import { orderBy } from 'lodash';
import moment from 'moment-timezone';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  styled,
  Typography
} from '@material-ui/core';
import { Link } from "react-router";
import { simpleNotification } from '../../utils/notifications';
// import { Field, FieldArray, reduxForm } from 'redux-form';
// import CustomDropDownArray from '../../../../utils/redux-forms-custom-fields/CustomDropDownArray';
import './Orders.scss';
import { getCurryFormattedString, roundingMoney } from "../../utils/functions";
import PromptDialog from "../dialog/PromptDialog";
import OrderConfirmDialog from "../dialog/OrderConfirmDialog";

const cancelReason = [
  { label: 'User Cancellation', value: 'user', color: 'green' },
  { label: 'Cancel After Timeout', value: 'timeout', color: 'white' },
  { label: 'Other', value: 'admin', color: 'red' },
];
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
  '& .MuiDialog-paperWidthSm': {
    backgroundColor: '#322d38',
    borderRadius: '8px',
    display: 'flex',
    alignItems: 'center',
    width: '600px',
    padding: '10px',
    overflowX:'hidden'
  },
  '& .MuiDialogContent-dividers': {
    borderTop: '0px',
    borderBottom: '0px'
  },
  '& .MuiTypography-h6': {
    textTransform: 'none'
  },
  '& .group_7': {
    width: '305px',
    height: '52px',
    margin: '32px 0 0 100px'
  },
  '& .flex-row': {
    display: 'flex',
    flexDirection: 'row'
  },
  '& .flex-col': {
    display: 'flex',
    flexDirection: 'column'
  },
  '& .text-wrapper_13': {
    width: '137px',
    height: '52px',
    overflowWrap: 'break-word',
    fontSize: '0',
    fontFamily: 'MicrosoftYaHei',
    fontWeight: 'NaN',
    textAlign: 'right',
    whiteSpace: 'nowrap',
    lineHeight: '34px'
  },
  '& .text_14': {
    width: '137px',
    height: '52px',
    overflowWrap: 'break-word',
    color: '#48EF37',
    fontSize: '36px',
    fontFamily: 'MicrosoftYaHei',
    fontWeight: 'NaN',
    textAlign: 'left',
    whiteSpace: 'nowrap',
    lineHeight: '34px',
  },
  '& .text_15': {
    width: '137px',
    height: '52px',
    overflowWrap: 'break-word',
    color: '#48EF37',
    fontSize: '60px',
    fontFamily: 'MicrosoftYaHei',
    fontWeight: 'NaN',
    textAlign: 'left',
    whiteSpace: 'nowrap',
    lineHeight: '34px'
  },
  '& .text-wrapper_14': {
    width: '305px',
    height: '18px',
    margin: '35px 0 0 0px'
  },
  '& .text_12': {
    width: '305px',
    height: '18px',
    overflowWrap: 'break-word',
    color: 'rgba(255, 255, 255, 1)',
    fontSize: '16px',
    fontFamily: 'MicrosoftYaHei-Bold',
    fontWeight: '700',
    textAlign: 'center',
    whiteSpace: 'nowrap',
    lineHeight: '18px'
  },
  '& .block_6': {
    width: '542px',
    height: '204px',
    margin: '19px 0 0 0px'
  },
  '& .text-wrapper_9': {
    backgroundColor: 'rgba(33, 28, 39, 1)',
    borderRadius: '7px',
    height: '204px',
    width: '542px',
    overflowY:'auto'
  },
  '& .paragraph_2': {
    width: '156px',
    //height: '154px',
    overflowWrap: 'break-word',
    color: 'rgba(205, 204, 208, 1)',
    fontSize: '16px',
    fontFamily: 'MicrosoftYaHei',
    fontWeight: 'NaN',
    textAlign: 'left',
    lineHeight: '34px',
    margin: '0px 0 0 24px',
  },
  '& .paragraph_3': {
    width: '305px',
    //height: '151px',
    overflowWrap: 'break-word',
    color: 'rgba(217, 215, 215, 1)',
    fontSize: '16px',
    fontFamily: 'MicrosoftYaHei',
    fontWeight: 'NaN',
    textAlign: 'right',
    lineHeight: '34px',
    margin: '0px 42px 0 0px'
  },
  '& .text-wrapper_10': {
    height: '48px',
    backgroundSize: '100% 100%',
    width: '250px',
    backgroundColor:'#ff0000',
    borderRadius:'100px',
    cursor:'pointer',
    justifyContent:'center'
  },
  '& .text-wrapper_11': {
    height: '48px',
    backgroundSize: '100% 100%',
    width: '250px',
    backgroundColor:'#808280',
    borderRadius:'100px',
    cursor:'pointer',
    justifyContent:'center'
  },
  '& .text_13': {
    width: '100%',
    height: '24px',
    overflowWrap: 'break-word',
    color: 'rgba(255, 255, 255, 1)',
    fontSize: '18px',
    fontFamily: 'MicrosoftYaHei',
    fontWeight: 'NaN',
    textAlign: 'center',
    whiteSpace: 'nowrap',
    lineHeight: '24px',
    margin: '0px 0 0 0px'
  },
  '& .block_7': {
    width: '250px',
    height: '48px',
    margin: '0px 0 0px 0px'
  },
}));
class Orders extends React.Component {
  constructor(props) {
    super(props);
    //this.idCopy = React.createRef();
    this.props = props;
    this.state = {
      copyModal: false,
      questCopyId: null,
      selectGame: null,
      tab: 1,
      numPage: 1,
      totalPage: 1,
      filters: {
      },
      sorting: {

      },
      viewOrderId: "",
      viewPayType: "",
      timeLeft: 0,
      dialogConfirmOpen: false,
      dialogPayOpen: false,
      curOrder: null,
      dialogResultOpen: false,
      payErrorType: 0,
      cancelOrderId:"",
      sendEmail: ""
    };
    this.timerId = null;
  }

  componentDidMount() {
    this.doSearch();
  }

  componentDidUpdate(prevProps, prevState) {
    // only update chart if the data has changed
    if ((prevProps.user.cdkOrder.isLoading === true && this.props.user.cdkOrder.isLoading === false)) {
      // // When login fails or user is trying to hack/test out to date OAuth Code in query string:
      if (this.props.user.cdkOrder.errors.hasError) {
        simpleNotification({
          level: "error",
          title: this.props.user.cdkOrder.errors.message,
          message: "fail to cancel cdk order!",
        });
      } else {
        simpleNotification({
          level: "success",
          title: "Cancel cdk order successful.",
          message: "The order has been cancelled!",
        });
        this.setState({ viewOrderId: '' });
        this.doSearch(this.state.numPage);

      }
    }

    if ((prevProps.user.orderLink.isLoading === true && this.props.user.orderLink.isLoading === false)) {
      // // When login fails or user is trying to hack/test out to date OAuth Code in query string:
      //console.log(this.props.user.orderLink.errors.hasError);
      if (this.props.user.orderLink.errors.hasError) {
        this.setState({ payErrorType: -1, dialogResultOpen: true });
      } else {
        this.setState({ payErrorType: 1, dialogResultOpen: true });
      }
    }

    if ((prevProps.user.cdkOrder_p.isLoading === true && this.props.user.cdkOrder_p.isLoading === false)) {
      // // When login fails or user is trying to hack/test out to date OAuth Code in query string:
      if (this.props.user.cdkOrder_p.errors.hasError) {
        if (this.props.user.cdkOrder_p.errors.code === 'E_WALLET_AMOUNT') {
          this.setState({ payErrorType: 2, dialogResultOpen: true });
        } else {
          this.setState({ payErrorType: -1, dialogResultOpen: true });
        }
      } else {
        this.setState({ payErrorType: 0, dialogResultOpen: true, sendEmail: this.props.user.cdkOrder_p.data.sendEmail});
      }
    }
  }

  doSearch(page = 1) {
    const { token } = this.props.auth || { token: null };

    // Unify this four methods in one in the future
    this.props.getCDKOrders(page, JSON.stringify(this.state.filters), JSON.stringify(this.state.sorting), token, 0);
    //this.props.getCDKProducts('5bd21b6dd67cd0aeb135b733',0, JSON.stringify(this.state.filters), JSON.stringify(this.state.sorting), token, 0);
  }

  copyText = (event) => {
    const text = event.target.innerHTML;
    if (navigator && navigator.clipboard) {
      navigator.clipboard.writeText(text);
      simpleNotification({
        level: 'success',
        title: 'Successfully Copied',
        message: `${text}`,
        autoDismiss: 2,
      });
    }
  }

  showOrderView = (quest) => {
    const { viewOrderId } = this.state;
    if (quest.id === viewOrderId) {
      this.setState({ viewOrderId: "", viewPayType: "",cancelOrderId:"" });
      if (this.timerId) {
        clearInterval(this.timerId);
      }
    } else {
      this.setState({ viewOrderId: quest.id, viewPayType: quest.payType,cancelOrderId:"" });
      if (quest.cancelTime && quest.status === 'w') {
        const now = new Date().getTime();
        const timeout = Date.parse(quest.cancelTime);
        //console.log(quest.cancelTime,now,timeout,timeout-now);
        if (timeout > now) {
          this.setState({ timeLeft: Math.ceil((timeout - now) / 1000) }, () => { this.startTimer() })
        } else {
          this.setState({ timeLeft: 0 });
        }
        /*const timestamp = Date.parse(quest.orderTime);
        if (timestamp) {
          const now = new Date().getTime();
          if (timestamp + 1800000 > now) {
            this.setState({ timeLeft: Math.ceil((timestamp + 1800000 - now) / 1000) }, () => { this.startTimer() })
          } else {
            this.setState({ timeLeft: 0 });
          }
        }*/
      }
    }
  }

  changePayType = (payType) => {
    this.setState({ viewPayType: payType });
  }

  formatTime = (seconds) => {
    if (seconds < 60) {
      return `${String(seconds).padStart(2, '0')}`;
    } else {
      const minutes = Math.floor(seconds / 60);
      const remainingSeconds = seconds % 60;
      return `${String(minutes).padStart(2, '0')}:${String(remainingSeconds).padStart(2, '0')}`;
    }
  }

  generateRowsHTML = (quests,wallet) => {
    if (quests.length >= 1) {
      return quests.map((quest, index) => (
        <div>
          <div className="group_2 flex-row" onClick={this.showOrderView.bind(this, quest)}>
            <div className="group_3 flex-col" style={{ backgroundImage: `url(${quest?.gameIcon})`, backgroundSize: 'contain',backgroundRepeat:'no-repeat',backgroundPosition:'center' }}></div>
            <div className="text-wrapper_4 flex-col justify-between">
              <span className="text_5" title={quest.gameName}>{quest.gameName}</span>
              <span className="text_6" title={quest.productName}>{quest.productName}</span>
            </div>
            <div className="group_4 flex-col"></div>
            <div className="text-wrapper_5">
              <span className="text_7">$</span> <span className="text_8">{quest.balance ? getCurryFormattedString(quest.balance) : '-'}</span>
            </div>
            <div className="group_5 flex-col"></div>
            <div className="text-wrapper_6">
              <span className="text_9">X</span> <span className="text_10">{quest.amount}</span>
            </div>
            <div className="group_6 flex-col"></div>
            <div className="text-wrapper_7 flex-col">
              {quest.status === 'w' &&(
                  <div style={{display:'flex',flexDirection:'column',width:'150px'}}>
                  <span className="text_11" >Pending</span>
                  <Link onClick={e=>this.showConfirmDialog(e,quest.id)}>CANCEL ORDER</Link>
                  </div>)
              }
              {quest.status === 'q' && <span className="text_57">Cancelled</span>}
              {quest.status === 'c' && <span className="text_31">Finished</span>}
            </div>
          </div>
          {this.state.viewOrderId === quest.id && this.generateRowsHTML1(quest,wallet)}
        </div>
      ));
    }
    return (<div className="NoQuests">No Records</div>);
  }

  generateRowsHTML1 = (quest,wallet) => {
    if (quest.status === 'w') {
      return (<div className="group_7 flex-col">
        <div className="text-wrapper_8 flex-row justify-between">
          <span className="text_12">Product&nbsp;Type:</span>
          <span className="text_13">{quest.productName}</span>
        </div>
        <div className="text-wrapper_9 flex-row justify-between">
          <span className="text_12">Platform&nbsp;Type:</span>
          <span className="text_13">{quest.platformName}</span>
        </div>
        <div className="text-wrapper_9 flex-row justify-between">
          <span className="text_14">Order&nbsp;ID:</span>
          <span className="text_15">{quest.orderId}</span>
        </div>
        <div className="text-wrapper_10 flex-row justify-between">
          <span className="text_16">Order&nbsp;Time:</span>
          <span className="text_17">{quest.orderTime ? moment(quest.orderTime).format('MMMM DD , YYYY - hh:mm a') : ''}</span>
        </div>
        <div className="section_1 flex-col"></div>
        <span className="text_18">Select&nbsp;Payment&nbsp;Method</span>
        <div className="section_2 flex-row justify-between">
          <div className="group_8 flex-row" onClick={this.changePayType.bind(this, 'noiz')}>
            {this.state.viewPayType === 'noiz' && <div className="group_9 flex-col"></div>}
            {this.state.viewPayType !== 'noiz' && <div className="box_4 flex-col"></div>}
            <div className="text-wrapper_11 flex-col">
              <span className="text_19">NOIZ</span>
            </div>
            <div className="text-wrapper_12">
              <span className="text_20"
              >NOIZ&nbsp;Wallet&nbsp;Balance&nbsp;(</span
              >
              <span className="text_21">＄{wallet!==undefined ? getCurryFormattedString(wallet) : '-'}</span>
              <span className="text_22">)</span>
            </div>
          </div>
          {/*<div className="group_10 flex-row" onClick={this.changePayType.bind(this, 'paypal')}>
            {this.state.viewPayType === 'paypal' && <div className="group_9 flex-col"></div>}
            {this.state.viewPayType !== 'paypal' && <div className="box_4 flex-col"></div>}
            <div className="image-text_1 flex-row justify-between">
              <div className="box_5 flex-col">PayPal</div>
              <span className="text-group_1">PayPal</span>
            </div>
          </div>*/}
        </div>
        <div className="section_2 flex-row justify-between">
          {this.state.timeLeft > 0 && <div className="text-wrapper_13 flex-col" onClick={this.showPayDialog.bind(this, quest)}>
            <span className="text_23">PAY&nbsp;NOW({this.formatTime(this.state.timeLeft)})</span>
          </div>}
          {this.state.timeLeft <= 0 && <div className="text-wrapper_13 flex-col">
            <span className="text_24" style={{ marginLeft: '122px' }}>CANCELLED</span>
          </div>}
          {this.state.timeLeft > 0 && <div className="text-wrapper_14 flex-col" onClick={e=>this.showConfirmDialog(e,quest.id)}>
            <span className="text_24">CANCEL&nbsp;ORDER</span>
          </div>}
        </div>
      </div>)
    } else if (quest.status === 'q') {
      return (<div className="group_14 flex-row">
        <div className="text-wrapper_27">
          <span className="text_58">Product&nbsp;Type：</span>
          <span className="text_59"></span>
          <span className="paragraph_5">{quest.productName}<br /></span>
          <span className="text_60">Order&nbsp;ID：</span>
          <span className="text_61"></span>
          <span className="text_62">{quest.orderId}<br /></span>
          <span className="text_64">Reason For Cancellation：</span>
          <span className="text_65">{quest.cancelReason ? cancelReason.find(s => s.value === quest.cancelReason).label : '-'}</span>
        </div>
        <div className="text-wrapper_28">
          <span className="text_58">Platform&nbsp;Type：</span>
          <span className="text_59"></span>
          <span className="paragraph_5">{quest.platformName}<br /></span>
          <span className="text_63">Order&nbsp;Time：</span>
          <span className="paragraph_6">{quest.orderTime ? moment(quest.orderTime).format('MMMM DD , YYYY - hh:mm a') : ''}<br /></span>
        </div>
      </div>)
    } else if (quest.status === 'c') {
      return (<div className="group_12 flex-row">
        <div className="text-wrapper_18">
          <span className="text_32">Product&nbsp;Type：</span>
          <span className="text_33"></span>
          <span className="paragraph_1">{quest.productName}<br /></span>
          <span className="text_34">Order&nbsp;ID：</span>
          <span className="text_35"></span>
          <span className="paragraph_2">{quest.orderId}<br /></span>
          <span className="text_36">Order&nbsp;Time：</span>
          <span className="text_37"></span>
          <span className="text_38">{quest.orderTime ? moment(quest.orderTime).format('MMMM DD , YYYY - hh:mm a') : ''}</span>
          <span className="paragraph_4"><br /></span>
          <span className="text_42">Send To Email：</span>
          <span className="text_43">{quest.sendEmail ? quest.sendEmail : ''}</span>
        </div>
        <div className="text-wrapper_19">
          <span className="text_32">Platform&nbsp;Type：</span>
          <span className="text_33"></span>
          <span className="paragraph_1">{quest.platformName}<br /></span>
          <span className="text_39">Payment&nbsp;Time：</span>
          <span className="paragraph_3">{quest.payTime ? moment(quest.payTime).format('MMMM DD , YYYY - hh:mm a') : ''}<br /></span>
          <span className="text_40">Payment&nbsp;Method：</span>
          <span className="text_41">{quest.payType}</span>
        </div>
      </div>)
    }
  }

  deleteQuest(quest, index) {
    if (window.confirm('This will delete the selected Quest, proceed?')) {
      const { token } = this.props.auth;
      fetch(`/api/v1/admin/quest/${quest.id}`, {
        method: 'delete',
        headers: {
          Authorization: `JWT ${token}`,
          'Content-Type': 'application/json',
        },
      }).then((response) => {
        this.props.commandCenter.quests.data.splice(index, 1);
        this.setState({ quests: this.props.commandCenter.quests.data });
      });
    }
  }

  copyCCQuest() {
    if (window.confirm('Are you sure about copy this Quest?')) {
      const { token } = this.props.auth;
      this.props.copyCCQuest(this.state.questCopyId, this.state.selectGame, token);
      this.setState({ copyModal: false });
    }
  }

  closeCopyModal = () => {
    this.setState({
      copyModal: false,
    });
  }

  renderCopyModal() {
    const { data: games } = this.props.commandCenter.games;
    // const { gameId } = this.props.router.params;
    let configGames = games.map(game => ({
      value: game.id,
      label: game.name,
    }));
    configGames = orderBy(configGames, ['label'], ['asc']);
    const renderSelectOptions = option => (<option key={option.value} value={option.value}>{option.label}</option>);
    return (
      <section className="ModalMaster active">
        <section className="Modal active">
          <button onClick={this.closeCopyModal.bind(this)} className="Modal__layer" aria-hidden />
          <section className="Modal__wrapper Copy">
            <div className="Modal__copy__header">
              Select the game
            </div>
            <div className="Modal__copy__body">
              <select
                onChange={event => this.setState({ selectGame: event.target.value })}
                className=""
                value={this.state.selectGame}
              >
                {configGames.map(renderSelectOptions)}
              </select>
            </div>
            <div className="Modal__copy__footer">
              <button
                onClick={this.closeCopyModal.bind(this)}
              >
                <i className="fa fa-times" /> Cancel
              </button>
              <button
                onClick={this.copyCCQuest.bind(this)}
              >
                <i className="fa fa-check" /> Copy
              </button>
            </div>
          </section>
        </section>
      </section>
    );
  }

  submitOrder = () => {
    const { token } = this.props.auth;
    this.props.buyCDK('67a9a7198ed89436480d1dd7', 2, 1, 'paypal', 'g', 'ginobili_spurs@163.com', token);
  }

  startTimer = () => {
    if (this.timerId) {
      clearInterval(this.timerId);
    }
    this.timerId = setInterval(() => {
      const { timeLeft } = this.state;
      const newTimeLeft = timeLeft - 1;
      this.setState({ timeLeft: newTimeLeft }, () => {
        if (this.state.timeLeft <= 0) {
          clearInterval(this.timerId);
        }
      });
    }, 1000);
  }

  cancelOrder = () => {
    const { token } = this.props.auth;
    const { cancelOrderId } = this.state;
    this.props.cancelCDKOrder(cancelOrderId, token);
    this.handleConfirmClose();
  }

  showConfirmDialog = (event,orderId) => {
    event.stopPropagation();
    this.setState({ dialogConfirmOpen: true,cancelOrderId: orderId });
  }

  handleConfirmClose = () => {
    this.setState({ dialogConfirmOpen: false,cancelOrderId:"" });
  }

  handleCancelOrder=(event,orderId)=>{
    event.stopPropagation();
    this.showConfirmDialog();
    this.setState({ cancelOrderId: orderId });
  }

  showPayDialog = (order) => {
    this.setState({ dialogPayOpen: true, curOrder: order });
  }

  handlePayClose = () => {
    this.setState({ dialogPayOpen: false });
  }

  payOrder = () => {
    const { token } = this.props.auth;
    const { viewOrderId, viewPayType } = this.state;
    if (viewPayType === 'noiz') {
      this.props.payCDKOrder(viewOrderId, viewPayType, token);
    } else if (viewPayType === 'paypal') {
      this.props.showCDKPay(viewOrderId, token);
    }

    this.handlePayClose();
  }

  paginationRight = () => {
    const { pagination } = this.props.user.cdkOrders;
    //const isAdmin = user.role === 'admin';
    //console.log(pagination.totalPages,this.state.numPage);
    if (pagination.totalPages < this.state.numPage + 1) {
      return;
    }

    if (!this.props.user.cdkOrders.isLoading) {
      //this.props.getWalletRollOutRecord(this.state.numPage+1, JSON.stringify(this.state.filters), JSON.stringify(this.state.sorting), token, 0);
      this.doSearch(this.state.numPage + 1)
    }
    this.setState({
      numPage: this.state.numPage + 1,
    });
  }

  paginationLeft = () => {
    //const isAdmin = user.role === 'admin';

    if (this.state.numPage === 1) {
      return;
    }

    if (!this.props.user.cdkOrders.isLoading) {
      //this.props.getWalletRollOutRecord(this.state.numPage-1, JSON.stringify(this.state.filters), JSON.stringify(this.state.sorting), token, 0);
      this.doSearch(this.state.numPage - 1)
    }
    this.setState({
      numPage: this.state.numPage - 1,
    });
  }

  handleResultClose = () => {
    this.setState({ dialogResultOpen: false, viewOrderId: "" });
    this.doSearch(this.state.numPage);
  };

  payPaypalOrder = () => {
    const { approvalLink, isLoading } = this.props.user.orderLink.data;
    //console.log(approvalLink);
    if (!isLoading) {
      if (approvalLink) {
        window.open(approvalLink, "_blank");
      }
    }
    this.handleResultClose();
  }

  toRecharge = () => {
    const host = window.location.hostname;
    const isLocal = host === 'localhost';
    const finalHost = isLocal ? `${host}:3000` : host;
    const protocol = isLocal ? 'https://' : 'https://';
    window.open(`${protocol}${finalHost}/my-account-detail/wallet`, '_self');
  }

  render() {
    //const { gameId } = this.props.router.params;
    const { isLoading, pagination = { totalUsers: 0, pageNumber: 0, totalPages: 0 }, data: cdkRecords = {},wallet } = this.props.user?.cdkOrders || { isLoading: false };
    const { tab, dialogConfirmOpen, dialogPayOpen, curOrder, payErrorType, dialogResultOpen,sendEmail } = this.state;
    if (isLoading) {
      //return (<i className="fa fa-spinner fa-spin fa-3x fa-fw" />);
    }

    //const today = moment().tz('America/Los_Angeles');
    //const hoursOffset = -today.utcOffset() / 60;

    //let activeQuests = filter(quests, x => (moment(x.endDateTime).tz('America/Los_Angeles').add(hoursOffset, 'hours') > today));
    //let finishedQuests = filter(quests, x => (moment(x.endDateTime).tz('America/Los_Angeles').add(hoursOffset, 'hours') <= today));

    //activeQuests = orderBy(activeQuests, ['endDateTime'], ['desc']);
    //finishedQuests = orderBy(finishedQuests, ['endDateTime'], ['desc']);

    //let showQuests = [];

    if (tab === 1) {
      //showQuests = [...activeQuests];
    } else if (tab === 2) {
      //showQuests = [...finishedQuests];
    }

    return (
      <section className="CCQuests3">
        <section className="ToggleTable">
          <div className="ToggleTable__header__right">
            {pagination.totalUsers > 0 && <div className="regular_title">RECORD TOTAL:{pagination ? pagination.totalUsers : '-'}</div>}
            {pagination.totalPages > 1 && (<div className="regular_title" style={{marginLeft:'10px'}}>PAGE:{pagination ? pagination.pageNumber : '-'}/{pagination ? pagination.totalPages : '-'}</div>)}
            {(pagination ? pagination.totalPages : 0) > 1 && (<button className="regular" onClick={this.paginationLeft.bind(this)}>
              <i className="fa fa-arrow-left" />
            </button>)}
            {(pagination ? pagination.totalPages : 0) > 1 && (<button className="regular" onClick={this.paginationRight.bind(this)}>
              <i className="fa fa-arrow-right" />
            </button>)}
          </div>
          {this.generateRowsHTML(cdkRecords,wallet)}
          <div className="ToggleTable__header__right">
            {pagination.totalUsers > 0 && <div className="regular_title">RECORD TOTAL:{pagination ? pagination.totalUsers : '-'}</div>}
            {pagination.totalPages > 1 && (<div className="regular_title">PAGE:{pagination ? pagination.pageNumber : '-'}/{pagination ? pagination.totalPages : '-'}</div>)}
            {(pagination ? pagination.totalPages : 0) > 1 && (<button className="regular" onClick={this.paginationLeft.bind(this)}>
              <i className="fa fa-arrow-left" />
            </button>)}
            {(pagination ? pagination.totalPages : 0) > 1 && (<button className="regular" onClick={this.paginationRight.bind(this)}>
              <i className="fa fa-arrow-right" />
            </button>)}
          </div>
        </section>

        <BootstrapDialog
          aria-labelledby="customized-dialog-title"
          open={!!dialogConfirmOpen}
        >

          <DialogContent dividers>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Typography variant='body1' component="div" gutterBottom style={{ minWidth: '150px', }}>Are you sure you want to cancel this order?</Typography>
            </div>
          </DialogContent>
          <DialogActions>
            <Button autoFocus style={{ fontSize: '16px', fontWeight: '800', backgroundColor: '#f77929', color: '#fff', borderRadius: '8px', padding: '10px 50px', boxShadow: '0px 4px 8px rgba(0,0,0,0.5)', textTransform: 'none' }} onClick={this.handleConfirmClose.bind(this)}>
              Cancel
            </Button>
            <Button style={{ marginLeft: '20px', fontSize: '16px', fontWeight: '800', backgroundColor: '#76b452', color: '#fff', borderRadius: '8px', padding: '10px 50px', boxShadow: '0px 4px 8px rgba(0,0,0,0.5)', textTransform: 'none' }} onClick={this.cancelOrder.bind(this)}>
              Confirm
            </Button>
          </DialogActions>
        </BootstrapDialog>
        <BootstrapDialog
          aria-labelledby="customized-dialog-title"
          open={!!false}
        >
          <DialogContent dividers>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Typography variant='body1' component="div" gutterBottom style={{ minWidth: '150px', }}>Product&nbsp;Type</Typography>
              <Typography variant='body1' component="div" gutterBottom style={{ minWidth: '200px', textAlign: 'right' }}>{curOrder?.productName}-{curOrder?.platformName}</Typography>
            </div>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Typography variant='body1' component="div" gutterBottom style={{ minWidth: '150px', }}>Price</Typography>
              <Typography variant='body1' component="div" gutterBottom style={{ minWidth: '200px', textAlign: 'right' }}>${getCurryFormattedString(curOrder?.price ? roundingMoney(curOrder?.price, 2) : '0.00')}</Typography>
            </div>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Typography variant='body1' component="div" gutterBottom style={{ minWidth: '150px', }}>Amount</Typography>
              <Typography variant='body1' component="div" gutterBottom style={{ minWidth: '200px', textAlign: 'right' }}>{curOrder?.amount}</Typography>
            </div>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Typography variant='body1' component="div" gutterBottom style={{ minWidth: '150px', }}>Total</Typography>
              <Typography variant='body1' component="div" gutterBottom style={{ minWidth: '200px', textAlign: 'right' }}>${getCurryFormattedString(curOrder?.balance ? roundingMoney(curOrder?.balance, 2) : '0.00')}</Typography>
            </div>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Typography variant='body1' component="div" gutterBottom style={{ minWidth: '150px', }}>Method Of Payment</Typography>
              <Typography variant='body1' component="div" gutterBottom style={{ minWidth: '200px', textAlign: 'right' }}>{this.state.viewPayType}</Typography>
            </div>

          </DialogContent>
          <DialogActions>
            <Button autoFocus style={{ fontSize: '16px', fontWeight: '800', backgroundColor: '#CAC1C4', color: '#fff', borderRadius: '8px', padding: '10px 50px', boxShadow: '0px 4px 8px rgba(0,0,0,0.5)', textTransform: 'none' }} onClick={this.handlePayClose.bind(this)}>
              Cancel
            </Button>
            <Button style={{ marginLeft: '20px', fontSize: '16px', fontWeight: '800', backgroundColor: '#76b452', color: '#fff', borderRadius: '8px', padding: '10px 50px', boxShadow: '0px 4px 8px rgba(0,0,0,0.5)', textTransform: 'none' }} onClick={this.payOrder.bind(this)}>
              Make Payment
            </Button>
          </DialogActions>
        </BootstrapDialog>
        <BootstrapDialog
          aria-labelledby="customized-dialog-title"
          open={!!false}
        >
          <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title" style={{ color: (payErrorType !== 0) ? '#ff0000' : '' }}>
            {payErrorType === -1 && 'Failure to payment'}
            {payErrorType === 1 && 'Do you want to redirect to the payment page to complete the payment?'}
            {payErrorType === 2 && 'NOIZ wallet has insufficient balance'}
            {payErrorType === 0 && 'Payment successful'}
          </DialogTitle>
          <DialogContent dividers>
          </DialogContent>
          <DialogActions>
            <Button style={{ marginLeft: '20px', fontSize: '16px', fontWeight: '800', backgroundColor: '#CAC1C4', color: '#fff', borderRadius: '8px', padding: '10px 50px', boxShadow: '0px 4px 8px rgba(0,0,0,0.5)', textTransform: 'none' }} onClick={this.handleResultClose.bind(this)}>
              Close
            </Button>
            {(payErrorType === 1) && (<Button style={{ marginLeft: '20px', fontSize: '16px', fontWeight: '800', backgroundColor: '#76b452', color: '#fff', borderRadius: '8px', padding: '10px 50px', boxShadow: '0px 4px 8px rgba(0,0,0,0.5)', textTransform: 'none' }} onClick={this.payPaypalOrder.bind(this)}>
              Confirm
            </Button>)}
            {(payErrorType === 100) && (<Button style={{ marginLeft: '20px', fontSize: '16px', fontWeight: '800', backgroundColor: '#76b452', color: '#fff', borderRadius: '8px', padding: '10px 50px', boxShadow: '0px 4px 8px rgba(0,0,0,0.5)', textTransform: 'none' }} onClick={this.toRecharge.bind(this)}>
              Confirm
            </Button>)}
          </DialogActions>
        </BootstrapDialog>
        {payErrorType === 0&&dialogResultOpen&&<PromptDialog dialogOpen={dialogResultOpen} level="success" title="Payment successful!" content={'Your CDKEY has been sent to '+sendEmail} cancelAction={this.handleResultClose}/>}
        {payErrorType === -1&&dialogResultOpen&&<PromptDialog dialogOpen={dialogResultOpen} level="failure" title="Failure to payment." content={'The amount in your NOIZ wallet is abnormal. Please contact the website administrator.'} cancelAction={this.handleResultClose}/>}
        {payErrorType === 1&&dialogResultOpen&&<PromptDialog dialogOpen={dialogResultOpen} level="success" title="Do you want to redirect to the payment page to complete the payment?" content={''} confirmButtonName="Make Payment" confirmAction={this.payPaypalOrder} cancelButtonName='cancel' cancelAction={this.handleResultClose}/>}
        {payErrorType === 2&&dialogResultOpen&&<PromptDialog dialogOpen={dialogResultOpen} level="warn" title="NOIZ wallet has insufficient balance" content={'The balance in your Noiz account in insufficient.'} cancelAction={this.handleResultClose}/>}
        {dialogPayOpen===true&&<OrderConfirmDialog curOrder={curOrder} button1Action={this.handlePayClose} button1Name="Cancel" button2Action={this.payOrder} button2Name="Make Payment"/>}
      </section>
    );
  }
}

Orders.propTypes = {

};

export default Orders;
