import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { Tooltip } from '@material-ui/core';
import { v4 as uuidv4 } from 'uuid';
import questBackgrounds from '../../images/backgrounds/questPage/index';
import { simpleNotification } from '../../utils/notifications';
// import ReactHtmlParser from 'react-html-parser';

// Components (internal)
import Countries from './Countries';
import FAQs from './FAQs';
import GameGallery from './GameGallery';
import GameSection from './GameSection';
import GamesPlayed from './GamesPlayed';
import Languages from './Languages';
import QuestModal from './QuestModal';
import QuestSelector from './QuestSelector';
import RequiredStreamTime from './RequiredStreamTime';
import StatusBar from './StatusBar';

// Components (external)
// import ItemCard from './ItemCard';
import VideoWidget from './VideoWidget';

import { longFade } from '../../utils/transitionIndex';
import Loading from '../loading/Loading';

// Styles
import './Quest.scss';
import Axios from 'axios';

import {
  getDefaultIcon,
  isMongoId,
  isStreamingQuest,
  // onlyUniqueByKey,
  wordTruncate,
  isAutoStreamQuest
  // htmlToPlainText
} from '../../utils/functions';
import VerificationCode from "../../global/components/verification-code/VerificationCode";
import UserDailyRankingData from './UserDailyRankingData';
import TwitchAuthModal from "./TwitchAuthModal";

const TABLET_SCREEN_WIDTH = 1024;

// Need addressing for Status
const status = '';

const returnBGStyle = (activeBackground, defaultBg) => {
  const defaultBack = `linear-gradient(to top, transparent 60%, rgba(24, 25, 29, 1) 95%), linear-gradient(to bottom, transparent 60%, rgba(24, 25, 29, 1) 95%), linear-gradient(to left, transparent 60%, rgba(24, 25, 29, .9) 98%), linear-gradient(to right, transparent 60%, rgba(24, 25, 29, .9) 98%), url(${activeBackground})`;
  const noGallery = `linear-gradient(to top, transparent 60%, rgba(24, 25, 29, .5) 95%), linear-gradient(to bottom, transparent 60%, rgba(24, 25, 29, .5) 95%), linear-gradient(to left, transparent 60%, rgba(24, 25, 29, .4) 98%), linear-gradient(to right, transparent 60%, rgba(24, 25, 29, .4) 98%),url(${defaultBg}`;
  const switchSpread = activeBackground ? { backgroundImage: defaultBack } : { backgroundImage: noGallery };
  return {
    transition: 'all .5 ease',
    backgroundAttachment: 'fixed',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    ...switchSpread,
  };
};

const fillUpGallery = (gallArr = []) => {
  const gradients = questBackgrounds;
  const preppedGrads = gradients.map(item => ({ url: '', pureGrad: item }));
  const gallLength = gallArr.length || 0;
  let temp = [];
  if (!gallArr || !gallLength) return preppedGrads;
  if (gallLength < 4) {
    temp = [...gallArr, ...preppedGrads];
  } else {
    temp = [...gallArr];
  }
  temp.length = 6;
  return temp;
};

const Quest = (props) => {
  const {
    location,
    router,
    auth: {
      token,
      user = {
        connectedAccounts: { twitch: {} },
        statistics: {},
      },
    },
    config: {
      quest: { data: quest = {} },
    },
    home: {
      quests: { all: { data: { legacy: quests = [] } } },
    },
    user: {
      myUserQuests: { data: userQuests = [], errors = {}, isLoading: myUserQuestsLoading },
    },
    getAllGuestQuests,
    getMyQuestsPageData,
    getOptimizedAllQuests,
    getQuestByIdentifier,
    getQuestBySlugGuest,
    validateUserHandshake,
    joinQuest,
    getInitialInfo,
  } = props;

  const {
    countries = [],
    faqs = [],
    game = {},
    id: questId = '',
    languages = ['en'],
    prevGamesPlayed = [],
    requiredStreamTimeMinutes = 0,
    requirements,
    description: questDescription = '',
    stepsPage1: questions = [],
    title = 'Quest Title',
    viewerTiers = [],
  } = quest;

  const {
    categories: genres = [],
    cover: coverImage = '',
    gamePageURL = '',
    headline: tagline = '',
    headlineVideo = '',
    logo = '',
    description = '',
    developer = '',
    gallery = [],
    id: gameId = '',
    name = '',
    publisher = '',
    sponsorType = 'game',
  } = game;
  // const videoBgRef = React.createRef();
  // The following lines is required because you can't default values for NULL. Yay JavaScript.
  const reqs = requirements || { beforeStream: [], duringStream: [], afterStream: [] };
  const { beforeStream, duringStream, afterStream } = reqs;
  const minACCV = viewerTiers && viewerTiers.length ? viewerTiers.reduce((prev, curr) => (prev < curr.minimumAverageViewers ? prev : curr.minimumAverageViewers), Number.MAX_SAFE_INTEGER) : false;
  const deliverables = [...(beforeStream || []), ...(duringStream || []), ...(afterStream || [])];
  const filteredGallery = gallery.filter(g => g.type === 'image');
  // const preppedCarouselQuests = quests ?
  //   quests.filter(item => item.slug !== quest.slug).map((q) => {
  //     const {
  //       game: { cover = '' },
  //       slug: questSlug = '',
  //       title: questTitle = '',
  //     } = q;

  //     return { slug: questSlug, questTitle, cover };
  //   })
  //   : [];

  const [questSelectorSticky, setQuestSelectorSticky] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [navStickyBottomPos, setNavStickyBottomPos] = useState(0);
  const [activeQuestionIndex, setActiveQuestionIndex] = useState(0);
  const [userQuest, setUserQuest] = useState({});
  const [bgIndex, setBgIndex] = useState(0);
  const [verificationCodeNeed, setVerificationCodeNeed] = useState(false);
  const [verificationCode, setVerificationCode] = useState('');
  const [verificationType, setVerificationType] = useState('');
  const [showTwitchAuthModal, setShowTwitchAuthModal] = useState(false);
  const [isVerifying, setIsVerifying] = useState(false);

  const QuestContainer = useRef();
  const StopTheDrop = useRef();

  // Listener function for window scroll
  const handleScroll = () => {
    const width = window.innerWidth > TABLET_SCREEN_WIDTH;
    if (QuestContainer.current) { // Handle side nav sticky on scroll
      // Stick when we hit top of scroll container
      if (window.scrollY >= 100 && width) {
        setQuestSelectorSticky(true);
        // If we pass top of container on the way back up, unstick
      } else {
        setQuestSelectorSticky(false);
      }
    }

    // Confirm if we can see footer or not
    const footerVisible = window.innerHeight - StopTheDrop.current.getBoundingClientRect().top >= 150;
    // Make quest selector fixed when footer is in view
    if (footerVisible && !navStickyBottomPos && width) {
      setNavStickyBottomPos(window.scrollY);
    } else if (!footerVisible) {
      setNavStickyBottomPos(false);
    }
  };

  const filledGallery = fillUpGallery(filteredGallery) || [];
  const backgroundImages = filledGallery.map(({ url, pureGrad = '' }, i) => {
    // 获取 longFade 返回的所有属性，并剔除 in 属性
    const fadeProps = longFade(3000, 3000, true, true, true);
    const { in: fadeIn, ...transitionProps } = fadeProps;

    return (
      <CSSTransition key={i} in={fadeIn} {...transitionProps}>
        <div className="bg-manager" style={returnBGStyle(url, pureGrad)} />
      </CSSTransition>
    );
  });

  // 新增 handleApply 方法
  const handleApply = async () => {
    if (!isAutoStreamQuest(quest)) {
      setModalOpen(true);
      return
    }
    // setModalOpen(true);
    // 示例接口调用，请根据实际情况构造请求体＆接口 URL
    setIsVerifying(true);
    const { validateUserHandshake } = props;
    const { user, token } = props.auth;
    const body = {};
    const config = {
      headers: {
        Authorization: `JWT ${token}`,
        'Content-Type': 'application/json',
      },
    };

    const flag = user.connectedAccounts.twitch.scope && user.connectedAccounts.twitch.scope.length > 0;
    try {
      // 调用接口
      const url = flag ? '/api/v1/twitch/subscriptions' : '/api/v1/twitch/check';
      let { data } = await Axios.get(url, body, config);
      // 检查 data.success 字段来确定结果
      if (flag) {
        // 成功情况
        if (data.success) {
          // 成功情况
          setModalOpen(true);
        } else if (data.errorCode === 'FETCH_SUBSCRIPTIONS_ERROR') {
          const url = '/api/v1/twitch/check'
          const { data } = await Axios.get(url, body, config);
          if (data.success) {
            setShowTwitchAuthModal(true);
            validateUserHandshake(token, '');
          }
        } else {
          // 失败情况
          // 出错时也走原有逻辑
          simpleNotification({
            level: 'error',
            title: 'Connection Error',
            message: 'Network issue, please try again.',
            position: 'tr',
            autoDismiss: 5
          });
        }
      } else {
        if (data.success) {
          // 失败情况
          setShowTwitchAuthModal(true);
          validateUserHandshake(token, '');
        } else {
          // 出错时也走原有逻辑
          simpleNotification({
            level: 'error',
            title: 'Connection Error',
            message: 'Network issue, please try again.',
            position: 'tr',
            autoDismiss: 5
          });
        }
      }
    } catch (err) {
      // 出错时也走原有逻辑
      simpleNotification({
        level: 'error',
        title: 'Connection Error',
        message: 'Network issue, please try again.',
        position: 'tr',
        autoDismiss: 5
      });
    } finally {
      setIsVerifying(false);
    }
  };

  useEffect(() => {
    const path = window.location.pathname;
    const pathArr = path.split('/');
    const identifier = pathArr[pathArr.length - 1];
    const isQuestId = isMongoId(identifier);
    if (token) {
      getQuestByIdentifier(identifier, token, isQuestId ? 'id' : 'slug');
      getMyQuestsPageData(token);
      getOptimizedAllQuests('all', 1, token);
      validateUserHandshake(token, '');
    } else {
      getQuestBySlugGuest(identifier);
      getAllGuestQuests();
    }
  }, []);

  // Set up Background Images Interval
  useEffect(() => {
    let i = 0;
    const bgInterval = setInterval(() => {
      i += 1;
      if (i >= backgroundImages.length - 1) i = 0;
      setBgIndex(i);
    }, 18000);
    return () => clearInterval(bgInterval);
  }, []);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  });

  useEffect(() => {
    const foundUQ = userQuests.find(q => q.quest === quest.id);
    if (foundUQ) setUserQuest(foundUQ);
    return () => setUserQuest({});
  }, [userQuests]);

  // const bgItemCards = onlyUniqueByKey(preppedCarouselQuests, 'cover').map((item, i) => (
  //   <ItemCard
  //     key={item.id || i}
  //     item={{
  //       image: item.cover,
  //       title: item.title || item.questTitle,
  //       slug: item.slug,
  //     }}
  //     showLearnMore
  //   />
  // ));

  // const oneMoreQuestOnly = bgItemCards && bgItemCards.length === 1;
  const hasRequirements = !!minACCV || !!countries.length || !!languages.length || !!prevGamesPlayed.length;
  const sameGameQuests = quests.filter(q => q.game && q.game.id === gameId);
  const filteredDeliverables = deliverables.filter(dev => dev.title && dev.showOnApp);
  const finalDeliverables = filteredDeliverables.length ? filteredDeliverables : deliverables.filter(dev => dev.title);
  const enableVideoWidget = headlineVideo || coverImage;
  const streamingQuest = isStreamingQuest(quest);

  const chealNextQuestion = (verificationType, verificationCode) => {
    setActiveQuestionIndex(activeQuestionIndex + 1)
    setVerificationCode(verificationCode)
    setVerificationType(verificationType)
  };

  const getVCode = () => {
    return { verificationType: verificationType, verificationCode: verificationCode };
  }

  const closeVerficationCode = () => {
    setVerificationCodeNeed(false);
  }

  const validateNeedVCode = (isProfileChange) => {
    if (activeQuestionIndex === 0 && isProfileChange === true) {
      setVerificationCodeNeed(true);
    } else {
      setVerificationCodeNeed(false);
      chealNextQuestion();
    }
  }

  // const changeText = window.innerWidth <= 800;

  // const mainDesc = questDescription ? ReactHtmlParser(questDescription) : htmlToPlainText(description).trim();

  return (
    <>
      <Loading isLoading={!quest} />

      <div className="BigContainer">
        {!!backgroundImages.length &&
          <div
            style={{
              position: 'fixed',
              top: '0px',
              left: '0px',
              width: '100vw',
              height: '100vh',
              opacity: 0.23,
              overflow: 'hidden',
              zIndex: 0, // 根据需要调整层级
            }}
          >
            <TransitionGroup>
              {/* <CSSTransition {...longFade(0, 3000, false, false, true)}>
                <video ref={videoBgRef} autoPlay muted playbackrate="0.2" loop id="maintenance-mode-myVideo" style={{ opacity: '.15' }}>
                  <source src="https://http-error-pages.s3.us-west-1.amazonaws.com/spaceBack.5f74cb3c.mp4" type="video/mp4" />
                </video>
              </CSSTransition> */}
              {backgroundImages[bgIndex]}
            </TransitionGroup>
          </div>
        }
        <div className="ContainerWrapper">
          <div className="ContainerSummary">
            {enableVideoWidget && <div className="left">
              <VideoWidget img={coverImage} src={headlineVideo} />
            </div>}

            <div className="right">
              <QuestSelector
                currentQuest={quest}
                handleApply={handleApply}
                navStickyBottomPos={navStickyBottomPos}
                questDates={sameGameQuests}
                status={status}
                sticky={questSelectorSticky && !navStickyBottomPos}
                title={title}
                user={user}
                userQuest={userQuest}
                token={token}
                stopWidth={TABLET_SCREEN_WIDTH}
                errors={errors}
                userQuestIsLoading={myUserQuestsLoading}
                router={router}
                location={location}
                validateUserHandshake={validateUserHandshake}
              />
            </div>
          </div>
          <div className="ContainerDetail">
            <div className="ContainerDetailLeft">
              <div className="ContainerDetailLeftGameSection">
                <div className="gameImage">
                  <div className="ContainerDetailLeftGameSectionImageContainer">
                    <img src={logo} alt="client logo" />
                  </div>
                  <button className="ContainerDetailLeftGameSectionMore" onClick={() => window.open(`/game/${gameId}`, '_blank')}>
                    Learn More
                  </button>
                </div>
              </div>
            </div>
            <div className="ContainerDetailRight">
              <div className="control-container">
                <div
                  className={classNames('NoizModal', {
                    open: modalOpen,
                  })}
                  onClick={() => setModalOpen(false)}
                />
                <div
                  className={classNames('NoizModalOverlay', {
                    open: modalOpen,
                  })}
                />
                <QuestModal
                  activeQuestionIndex={activeQuestionIndex}
                  closeModal={() => setModalOpen(false)}
                  joinQuest={joinQuest}
                  nextQuestion={validateNeedVCode}
                  open={modalOpen}
                  prevQuestion={() => setActiveQuestionIndex(activeQuestionIndex - 1)}
                  questId={questId}
                  setActiveQuestionIndex={setActiveQuestionIndex}
                  questions={questions}
                  token={token}
                  getInitialInfo={getInitialInfo}
                  vCode={getVCode}
                />
                <StatusBar status={status} />
                <div className="QuestContainer" id="quest-container" ref={QuestContainer}>
                  {!!deliverables.length && (
                    <div className="DeliverablesContainer make-visible" style={{ margin: !headlineVideo ? '0% 0% 0% 0%' : '0px 0px 0px 0px' }}>
                      <div className="deliverablesList_title"> Deliverables </div>
                      <div className="deliverablesList make-visible">
                        {finalDeliverables.map(dev => (
                          <div
                            className="deliverable make-visible"
                            key={uuidv4()}
                          >
                            <div className="iconBg">
                              <img
                                alt="icon"
                                className="icon"
                                src={getDefaultIcon(dev.type, dev.subtype || '', quest, game)
                                  ? getDefaultIcon(dev.type, dev.subtype || '', quest, game)
                                  : undefined}
                              />
                            </div>
                            <Tooltip title={dev.appPageTitle || dev.title} placement="top">
                              <span>
                                {wordTruncate(dev.appPageTitle || dev.title, 14)}
                              </span>
                            </Tooltip>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                  {(<GameSection
                    gameDescription={description}
                    questDescription={questDescription}
                    developer={developer}
                    sponsorType={sponsorType}
                    gamePageURL={gamePageURL}
                    genres={genres}
                    id={gameId}
                    logo={logo}
                    gameTitle={name}
                    publisher={publisher}
                    tagline={tagline}
                    tabWidth={800}
                  />)}
                  {isAutoStreamQuest(quest) && (<div className="platformSelectedList">
                    <button className="platform-item-twitch-btn-selector active" data-platform="twitch">
                      <svg className="platform-twitch-btn-icon-selector" viewBox="0 0 24 24">
                        <path d="M11.571 4.714h1.715v5.143H11.57zm4.715 0H18v5.143h-1.714zM6 0L1.714 4.286v15.428h5.143V24l4.286-4.286h3.428L22.286 12V0zm14.571 11.143l-3.428 3.428h-3.429l-3 3v-3H6.857V1.714h13.714Z" />
                      </svg>
                      <div className="platform-item-twitch-btn-selector-text">
                        Twitch
                      </div>
                    </button>
                  </div>)}

                  {isAutoStreamQuest(quest) && (<UserDailyRankingData current={quest} />)}
                  {faqs && !!faqs.length && <FAQs faqs={faqs} />}
                  {filteredGallery.length > 0 && <GameGallery gallery={filteredGallery} />}
                  {hasRequirements && (
                    <div className="EligibilityContainer make-visible">
                      <div className='common_title'>Eligibility Requirements</div>
                      <div className="eligibilityList">
                        {(!!minACCV && minACCV > 0) && (
                          <div className="eligibilityItem make-visible-new">
                            <span className="minACCV">{minACCV}</span>
                            <Tooltip title="Average Concurrent Viewers" placement="bottom">
                              <span className="label">Minimum ACCV</span>
                            </Tooltip>
                          </div>
                        )}
                        {!!countries.length && (
                          <Countries className="eligibilityItem make-visible-new" countries={countries} />
                        )}
                        {!!languages.length && (
                          <Languages className="eligibilityItem make-visible-new" languages={languages} />
                        )}
                        {!!prevGamesPlayed.length && (
                          <GamesPlayed className="make-visible-new" games={prevGamesPlayed} />
                        )}
                        {streamingQuest && requiredStreamTimeMinutes > 0 &&
                          <RequiredStreamTime className="make-visible-new" isStreamingQuest={streamingQuest} requiredStreamTimeMinutes={requiredStreamTimeMinutes} />
                        }
                      </div>
                    </div>
                  )}
                </div>
                {verificationCodeNeed && <VerificationCode verificationType='profileInfo' close={closeVerficationCode} submitNext={chealNextQuestion} {...props} />}
                <div className="stop-the-drop" ref={StopTheDrop} />


              </div>
            </div>
          </div>
        </div>
        {isVerifying && (
          <div className="verifying-overlay">
            <div className="verifying-modal">
              <span>Twitch authorization in progress...</span>
            </div>
          </div>
        )}
        {showTwitchAuthModal && (
          <TwitchAuthModal
            onClose={() => setShowTwitchAuthModal(false)}
          />
        )}
      </div>
    </>
  );
};

export default Quest;
