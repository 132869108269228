/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  styled,
  Typography
} from '@material-ui/core';
// import { Field, FieldArray, reduxForm } from 'redux-form';
// import CustomDropDownArray from '../../../../utils/redux-forms-custom-fields/CustomDropDownArray';
import rcIcon from '../../images/dialog/rc.png';
import fcIcon from '../../images/dialog/fc.png';
import successIcon from '../../images/dialog/success.png';
import fail1Icon from '../../images/dialog/fail1.png';
import fail2Icon from '../../images/dialog/fail2.png';
import wcIcon from '../../images/dialog/wc.png';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
  '& .MuiDialog-paperWidthSm': {
    backgroundColor: '#322d38',
    borderRadius:'8px',
    display:'flex',
    alignItems:'center',
    width:'600px',
    padding:'0px'
  },
  '& .MuiDialogContent-dividers': {
    borderTop:'0px',
    borderBottom:'0px',
    width:'100%'
  },
  '& .MuiTypography-h6': {
    textTransform:'none',
    fontSize:'1.8rem'
  },
  '& .input': {
    width:'100%',
    padding:'10px',
    backgroundColor:'#000000',
    border:'1px solid #3d3d3d',
    borderRadius:'4px',
    color:'#ffffff',
  },
  '& .input:focus': {
    outline:'1px solid #0d6efd',
  },
  '& .input_error': {
    width:'100%',
    padding:'10px',
    backgroundColor:'#1a1a1a',
    border:'2px solid #ff0000',
    borderRadius:'4px',
    color:'#ffffff',
  },
  '& .input_readonly': {
    width:'100%',
    padding:'10px',
    backgroundColor:'#000000',
    border:'2px solid #3d3d3d',
    borderRadius:'4px',
    color:'#6b5c5c',
  },
  '& select': {

  },
  '& Button': {
    padding:'10px 20px',
    border:'none',
    borderRadius:'4px',
    cursor:'pointer',
    fontSize:'14px',
    transition:'background 0.3s',
    fontFamily:'',
    textTransform:'capitalize',
  },
  '& .select_error': {
    border:'2px solid #ff0000',
    borderRadius:'4px',
    color:'#ffffff',
  },
  '& .css-1pcexqc-container': {
    width:'100%'
  },
  '& .css-ga0n51-control':{
    backgroundColor:'#000000'
  },
  '& .MuiDialogTitle-root':{
    backgroundColor:'none'
  },
  '& .image-wrapper_1':{
  height: '115px',
  background: `url(${rcIcon}) 100% no-repeat`,
  backgroundSize: '100% 100%',
  width: '115px',
  margin: '37px 0 0 0px',
    display:'flex',
    flexDirection:'column',
  },
  '& .image-wrapper_2':{
    height: '43px',
    background: `url(${fail2Icon}) 100% no-repeat`,
    backgroundSize: '44px 44px',
    width: '43px',
    margin: '37px 0 0 37px',
    display:'flex',
    flexDirection:'column',
  },
  '& .text-wrapper_3':{
    height: '115px',
    background: `url(${wcIcon}) 100% no-repeat`,
    backgroundSize: '100% 100%',
    width: '115px',
    margin: '37px 0 0 0px',
    display:'flex',
    flexDirection:'column',
  },
  '& .image_1':{
    width: '56px',
    height: '41px',
    margin: '40px 0 0 32px',
  },
  '& .label_1':{
    width: '43px',
    height: '43px',
  },
  '& .MuiTypography-gutterBottom':{
    textAlign:'center',
    wordWrap:"break-word",
    margin:'0 30px 0 30px'
  },
  '& .text-wrapper_1':{
    height: '48px',
    backgroundColor:'#EF3937',
    borderRadius:'100px',
backgroundSize: '100% 100%',
width: '250px',
margin: '39px 10px 39px 0',
    cursor:'pointer'
  },
  '& .text-wrapper_2':{
    height: '48px',
    backgroundColor:'rgb(118, 180, 82)',
    borderRadius:'100px',
    backgroundSize: '100% 100%',
    width: '250px',
    margin: '39px 10px 39px 0',
    cursor:'pointer'
  },
  '& .flex-col':{
    display:'flex',
    flexDirection:'column'
  },
  '& .text_3': {
  height: '18px',
  overflowWrap: 'break-word',
  color: 'rgba(255, 255, 255, 1)',
  fontSize: '18px',
  fontFamily: 'ArialMT',
  fontWeight: 'NaN',
  textAlign: 'center',
  whiteSpace: 'nowrap',
  lineHeight: '24px',
  margin: '13px 0 0 0px',
},
  '& .section_2': {
  height: '115px',
  background: `url(${fcIcon}) 100% no-repeat`,
backgroundSize: '100% 100%',
width: '115px',
margin: '37px 0 0 0px'
},
  '& .text_7': {
    width: '12px',
    height: '56px',
    overflowWrap: 'break-word',
color: 'rgba(239, 211, 55, 1)',
fontSize: '70px',
fontFamily: 'Adobe Heiti Std R',
fontWeight: 'NaN',
textAlign: 'left',
whiteSpace: 'nowrap',
lineHeight: '82px',
margin: '16px 0 0 50px'
  },

}));

class PromptDialog extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      open:props.dialogOpen,
    };
  }
  componentDidMount() {
  }

  handleClose=()=>{
this.setState({open:false});
}

  render() {
    const { level="success", title,content,confirmButtonName,cancelButtonName,confirmAction,cancelAction} = this.props;
    //const {open=false} = this.state;
    return (

      <BootstrapDialog
          aria-labelledby="customized-dialog-title"
          open={true}
      >
        {level==='success'&&<div className="image-wrapper_1 flex-col">
          <img
              className='image_1'
              referrerPolicy="no-referrer"
              src={successIcon}
              alt="wrapper"
          />
        </div>}
        {level==='failure'&&<div className="section_2 flex-col"><div className="image-wrapper_2 flex-col">
          <img
              className='label_1'
              referrerPolicy="no-referrer"
              src={fail1Icon}
              alt="wrapper"
          />
        </div></div>}
        {level==='warn'&&<div className="text-wrapper_3 flex-col">
          <span className="text_7">!</span>
        </div>}
          <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title" >
            {title}
          </DialogTitle>
          <DialogContent dividers>
            <div>
              <Typography variant='body1' component="div"  gutterBottom><div dangerouslySetInnerHTML={{__html:content}}></div></Typography>
            </div>
          </DialogContent>
          <DialogActions>
            <div className="text-wrapper_1 flex-col" onClick={cancelAction}>
              <span className="text_3">{cancelButtonName?cancelButtonName:'Okay'}</span>
            </div>
            {confirmButtonName&&<div className="text-wrapper_2 flex-col" onClick={confirmAction}>
              <span className="text_3">{confirmButtonName}</span>
            </div>}
          </DialogActions>

      </BootstrapDialog>

    );
  }
}

PromptDialog.propTypes = {

};

export default PromptDialog;
