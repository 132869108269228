/* eslint max-len: ["error", { "code": 800 }] */
import React, { useRef } from 'react';
import './AboutUsBeta.scss';
import IconParticles from '../icon-particles/IconParticles';
import noizVideo from './assets/NOIZ3.0.mp4';
function AboutUsBeata() {
  const videoRef = useRef(null);

  return (
    <section className="AboutUs">
      <section className="AboutUs__container">
        <section className="Hero">
          <IconParticles />
        </section>
        <section className="Intro">
          <section className="Intro__content">
            <p className="Intro__content--title">Welcome to Noiz 3.0 Beta!</p>
            <div className="Intro__content--details">
              Noiz just got even better! With Noiz 3.0 Beta, we're bringing bigger rewards, more ways to earn, and a smoother experience for streamers worldwide. Whether you've been with us from the start or are just discovering Noiz, there's never been a better time to join.
              <h4 className="no-bullet">🎮 What's New in Noiz 3.0 Beta?</h4>
              <ul>
                <li>
                  <h4 className="no-bullet">💰 Boosted Earnings & Prize Pools</h4>
                  <ul>
                    <li>Bigger Prize Pool, Bigger Earning!</li>
                    <li>Regular paid streaming quests are still here, but now select games come with bonus prize pools.</li>
                    <li>Stream for a set amount of time over multiple days and split a cash prize!</li>
                  </ul>
                </li>
                <li>
                  <h4 className="no-bullet">🏆 Leaderboard Bonus Mania</h4>
                  <ul>
                    <li>The top 3 streamers with the most hours watched will earn extra cash.</li>
                    <li>For example, if the top creators all achieve 20,000 watch hours or more:</li>
                    <li>
                      <ul>
                        <li className="no-bullet">🥇 1st place gets: $1,XXX</li>
                        <li>🥈 2nd place gets: $5XX</li>
                        <li>🥉 3rd place gets: $1XX</li>
                      </ul>
                    </li>
                  </ul>
                </li>
                <li>
                  <h4 className="no-bullet">💼 The Noiz Wallet is Here!</h4>
                  <ul>
                    <li>Your earnings will now be stored in your Noiz Wallet.</li>
                    <li>Keep your funds as long as you like and withdraw ANYTIME!</li>
                  </ul>
                </li>
                <li>
                  <h4 className="no-bullet">🛒 Noiz Store Upgrades</h4>
                  <ul>
                    <li>Use your wallet funds to grab CD keys and other rewards in the Noiz Store.</li>
                  </ul>
                </li>
              </ul>
              <br />
              This is just the beginning of Noiz 3.0. We're building more ways for streamers to earn and get rewarded. Ready to level up? <strong>Start streaming and get paid today!</strong>
              <br />
              <br />
              🔗 Join Noiz 3.0 Beta Now!
            </div>
          </section>
        </section>
        <section className="Brands">
          <section className="Brands__content">
            <div className="Brands__content--video-container">
              <video
                controls
                className="Brands__content--video"
                src={noizVideo}
                loop
                muted
                playsInline
                ref={videoRef}
              />
            </div>
          </section>
        </section>
      </section>
    </section>
  );
}

export default AboutUsBeata;