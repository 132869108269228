import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { Tooltip } from '@material-ui/core';
// import { v4 as uuidv4 } from 'uuid';

// import PopOver from './PopOver';
import StatusButton from './StatusButton';

import Axios from 'axios';  // 确保导入 Axios
import { simpleNotification } from '../../utils/notifications';

import { localizeIncorrectDate } from '../../utils/dateTime';
import { getPaymentAmountNew, rounding, getPlatformImages, isQuestOver, isNewTypeQuestRegistrationOver, isStreamingQuest, isAutoStreamQuest } from '../../utils/functions';

import './QuestSelector.scss';
import PromptDialog from "../dialog/PromptDialog"; // 添加缺少的导入

const format = 'MMM. DD, YYYY';
const dateRange = (start, end) => `${localizeIncorrectDate(start).format(format)} – ${localizeIncorrectDate(end).format(format)}`;
// const toMenuItem = ({
//   endDateTime, id, platforms, startDateTime,
// }) => (
//   <MenuItem
//     key={uuidv4()}
//     name={startDateTime}
//     value={id.toString()}
//   >
//     {`${dateRange(startDateTime, endDateTime)} (${(!!platforms.length && platforms[0].baseService) || 'All'})`}
//   </MenuItem>
// );

const QuestSelector = (props) => {
  const {
    router,
    location,
    currentQuest = {},
    handleApply,
    navStickyBottomPos = false,
    // questDates,
    status,
    sticky,
    title,
    user,
    userQuest = {},
    errors: { message = '' },
    userQuestIsLoading = false,
  } = props;

  const {
    // id: questId = '',
    memberTiers = [],
    platforms = [],
    // slug = '',
    viewerTiers = [],
    splitTiers = [],
    rankTiers = [],
    miniFollowers = 0,
  } = currentQuest;

  const {
    connectedAccounts: {
      twitch: { followers: twitchFollowers },
    },
  } = user;

  // const getSlug = (id) => {
  //   const q = questDates.find(qu => qu.id === id);
  //   return q.slug ? q.slug : slug;
  // };

  const [dates, setDates] = useState('');
  const [questPlatform, setQuestPlatform] = useState('');

  const [hasCheckedFollowers, setHasCheckedFollowers] = useState(false);
  const [isRefreshing, setIsRefreshing] = useState(false);

  useEffect(() => {
    // setDates(questId.toString());
    if (currentQuest?.id) {
      // 默认选中当前 quest，即设置 value 为 currentQuest.id 的字符串形式
      setDates(currentQuest.id.toString());
    }
    setQuestPlatform(platforms);
  }, [currentQuest]);

  // const handleDateEvent = (e) => {
  //   const id = e.target.value;
  //   if (id !== questId.toString()) {
  //     const newSlug = getSlug(id);
  //     window.location.assign(`/quests/${newSlug}`);
  //   }
  // };

  // 添加处理刷新关注者数量的方法
  const handleRefreshFollowers = async () => {
    if (isRefreshing) return;

    setIsRefreshing(true);

    try {
      const config = {
        headers: {
          Authorization: `JWT ${props.token}`,
          'Content-Type': 'application/json',
        },
      };

      const { data } = await Axios.get('/api/v1/twitch/broadcastfollowers', config);

      if (data && data.success) {
        // 成功更新关注者数量，调用 validateUserHandshake
        props.validateUserHandshake(props.token, '');

        // 显示成功消息
        // simpleNotification({
        //   level: 'success',
        //   title: 'Followers Updated',
        //   message: 'Your Twitch follower count has been refreshed.',
        //   position: 'tr',
        //   autoDismiss: 5
        // });
      } else {
        // 更新失败
        // simpleNotification({
        //   level: 'error',
        //   title: 'Update Failed',
        //   message: data.message || 'Failed to refresh follower count. Please try again later.',
        //   position: 'tr',
        //   autoDismiss: 5
        // });
      }
    } catch (error) {
      console.error('Error refreshing followers:', error);
      simpleNotification({
        level: 'error',
        title: 'Connection Error',
        message: 'Network issue, please try again.',
        position: 'tr',
        autoDismiss: 5
      });
    } finally {
      setIsRefreshing(false);
    }
  };

  // 添加新的 useEffect，自动检查并刷新关注者数量
  useEffect(() => {
    // 条件：有twitch账号，任务未结束，关注者数量不足，且未进行过检查
    if (!hasCheckedFollowers &&
      currentQuest?.id && twitchFollowers < miniFollowers && !isNewTypeQuestRegistrationOver(currentQuest)) {

      // 标记已检查过，防止重复调用
      setHasCheckedFollowers(true);

      // 自动调用刷新方法
      handleRefreshFollowers();
    }
  }, [twitchFollowers, miniFollowers, hasCheckedFollowers, currentQuest]);

  // 添加状态控制警告对话框的显示
  const [showWarningDialog, setShowWarningDialog] = useState(false);
  
  // 处理关闭警告对话框
  const handleWarningDialogClose = () => {
    setShowWarningDialog(false);
  };

  // 当组件接收到错误消息时自动显示对话框
  useEffect(() => {
    if (message) {
      setShowWarningDialog(true);
    }
  }, [message]);

  const classes = classNames({
    QuestSelector: true,
    status: !!status,
    sticky,
    stop: !!navStickyBottomPos,
  });

  // Merge currentQuest into questDates if not included already.
  // const mergedQuestDates = useMemo(() => {
  //   if (currentQuest?.id) {
  //     // Look for currentQuest in questDates (compare by string ID)
  //     const exists = questDates.find(q => q.id.toString() === currentQuest.id.toString());
  //     if (!exists) {
  //       // Prepend currentQuest into the list, so it appears as the default selected.
  //       return [currentQuest, ...questDates];
  //     }
  //   }
  //   return questDates;
  // }, [currentQuest, questDates]);

  // const questDateOptions = mergedQuestDates.map(toMenuItem);
  const ineligibleStatuses = ['accv-min', 'no-twitch'];
  const { paymentAmount = 0 } = getPaymentAmountNew(splitTiers || [], rankTiers || [], memberTiers || [], user, viewerTiers || [], userQuest || {});
  const eligible = status ? !ineligibleStatuses.includes(status) : paymentAmount !== -2;
  const freeKey = paymentAmount === -1;
  const points = paymentAmount === -2;

  let paymentText = `$${rounding(paymentAmount)}`;
  let disclaimer = 'This is an estimate and may be adjusted prior to acceptance.';

  const images_platforms = questPlatform && getPlatformImages(questPlatform);
  if (paymentAmount === Number.MIN_SAFE_INTEGER || !eligible) {
    paymentText = '--';
    disclaimer = null;
  } else if (freeKey) {
    paymentText = 'Free Key';
    disclaimer = null;
  } else if (points) {
    paymentText = 'Points';
  } else if (paymentAmount > 0) {
    disclaimer = null;
  }

  const questFlag = isQuestOver(currentQuest)

  const twitchExists = user.connectedAccounts.twitch.id || user.connectedAccounts.twitch.username;

  const PaymentRender = () => {
    if (!!disclaimer) {
      return (
        <Tooltip placement="top" title={disclaimer}>
          <div className='PaymentRender'>
            <span className={`payment-amount disclaimer ${freeKey || points ? 'free-key' : ''}`}>
              {paymentText}&nbsp;<i className="fa fa-exclamation-circle" />
            </span >
          </div>

        </Tooltip>
      )
    }

    return (
      <span className={`payment-amount ${freeKey || points ? 'free-key' : ''}`}>
        <div className='PaymentRender'>
          {paymentText}
        </div>
      </span>
    )
  };

  return (
    <React.Fragment>
      <div className={classes}>
        <Tooltip title={title} placement="top">
          <div className='text-wrap'>
            <div className={`title-quest-text ${title?.length > 80 ? "sm_text" : ""}`}>
              {title}
            </div>
          </div>

        </Tooltip>
        <div className="paymentBar">
          <div className="selector-red-line"></div>
          {isStreamingQuest(currentQuest) && (
            <div className="paymentBarTitle">You’ll Earn</div>
          )}
          {isAutoStreamQuest(currentQuest) && (
            <div className="paymentBarTitle">prize pool</div>
          )}
          {!isStreamingQuest(currentQuest) && !isAutoStreamQuest(currentQuest) && (
            <div className="paymentBarTitle">You’ll Earn</div>
          )}
          <PaymentRender />
        </div>
        {/**原来的目标值的展示，先删除，后续在修改 */}
        {/* {goalEnabled && goal && <div className="quest-goal-message">{goal}</div>} */}
        <div className="selections">
          {
            currentQuest && (
              <div className="questDates">
                <div className="questDatesTitle">Quest Dates</div>
                <div className="select-corrections-str">
                  {`${dateRange(currentQuest.startDateTime, currentQuest.endDateTime)} (${(currentQuest.platforms && !!currentQuest.platforms.length && currentQuest.platforms[0].baseService) || 'All'})`}
                </div>
              </div>
            )
          }
          {dates &&
            <div className="gamingPlatform">
              <div className="questDatesTitle">Platform or Service</div>
              {images_platforms && images_platforms.length > 0 ? (
                <div className="platform-image-list">
                  {images_platforms.map((image, index) => (
                    <img key={index} src={image} alt='images_platforms' className="platform-image" />
                  ))}
                </div>
              ) : (
                <div className="select-corrections-str" >None</div>
              )}
              <div className="selector-red-line" />
            </div>
          }
        </div>
        <div className="platformSelected">
          <div className="questDatesTitle">SELECT STREAMING PLATFORM</div>
          <button className="platform-twitch-btn-selector active" data-platform="twitch">
            <svg className="platform-twitch-btn-icon-selector" viewBox="0 0 24 24">
              <path d="M11.571 4.714h1.715v5.143H11.57zm4.715 0H18v5.143h-1.714zM6 0L1.714 4.286v15.428h5.143V24l4.286-4.286h3.428L22.286 12V0zm14.571 11.143l-3.428 3.428h-3.429l-3 3v-3H6.857V1.714h13.714Z" />
            </svg>
            <div className="platform-twitch-btn-selector-text">
              Twitch
            </div>
          </button>
        </div>
        <div className="buttonsBarQS">
          <StatusButton
            quest={currentQuest}
            user={user}
            userQuest={userQuest}
            onApply={handleApply}
            userQuestIsLoading={userQuestIsLoading}
            router={router}
            location={location}
          />
          {/* <PopOver /> */}
        </div>
        {twitchExists && !questFlag && (twitchFollowers < miniFollowers) && <div className="quest-insufficient-message">Insufficient followers (minimum {miniFollowers})</div>}
        {/* {!!message && <div className="quest-insufficient-message">{textTruncate(message, 33, '...')}</div>} */}
        <a
          className="help-button"
          href="/contact"
          style={{ cursor: 'pointer' }}
        >
          <i className="fa fa-question-circle" />Need help?
        </a>
      </div>

      {/* 添加警告对话框组件 */}
      {showWarningDialog && message && (
        <PromptDialog
          dialogOpen={showWarningDialog}
          level="warn"
          title="Quest Warning"
          content={message}
          cancelAction={handleWarningDialogClose}
          cancelButtonName="Okay"
        />
      )}
    </React.Fragment>
  );
};

export default QuestSelector;
