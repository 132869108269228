/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import { Link } from 'react-router';
import {
  without,
  find,
  filter,
  orderBy,
  sumBy,
  findIndex,
} from 'lodash';
import classNames from 'classnames';
import moment from 'moment-timezone';
import { CSVLink } from 'react-csv';
import { simpleNotification } from '../../../../utils/notifications';
import {
  getNumApprovedRequirements,
  getQuestRequirements,
  getReferralBonus,
  getTwitchAverageViewers,
  getUserQuestStatus,
  isStreamingQuest,
  isAutoStreamQuest,
  paymentVendorProcessingFee,
  rounding,
  viewerTiersWithSpacesLeft,
  getCompletionStatus,
  getTaskCompletionStatus,
  formatMinutesToHoursAndMinutes,
  formatValueWithUnit,
  getQuestCountdown
} from '../../../../utils/functions';
import EditModal from '../QuestTimeEditModal/EditModal';
import EditTier from '../edit-tier/EditTier';
import QuestDetailsTable from './QuestDetailsTable';
import QuestDetailsTableNew from './QuestDetailsTableNew';
import QuestRequirementsTable from './QuestRequirementsTable';
import QuestPaymentsTable from './QuestPaymentsTable';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import { withStyles } from '@material-ui/core/styles';

// Review Modals
import BrandSafetyModal from '../reputation-system/BrandSafetyModal';
import QualityModal from '../reputation-system/QualityModal';
import CongenialityModal from '../reputation-system/CongenialityModal';

import './QuestTiered.scss';
import QuestPaymentsTableNew from './QuestPaymentsTableNew';

const TableHeaderCell = withStyles(theme => ({
  head: {
    color: '#9b9b9b',
    fontWeight: '400',
    letterSpacing: '1.2px',
    fontSize: '14px',
  },
}))(TableCell);

class QuestTiered extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    const { questType } = props.params;

    this.state = {
      tab: questType === 'general' ? 2 : 1,
      showingElements: [],
      reviewRequirementSort: {
        column: 'noiz',
      },
      /* Reputation system */
      reputationRatings: [],
      brandSafetyModalOpen: false,
      qualityModalOpen: false,
      congenialityModalOpen: false,
      isEditingReputation: false,
      // Current user/modal values
      modalUser: false,
      questReqId: false,
      quality: {
        comments: '',
        rating: {
          value: 0,
          label: '',
        },
      },
      vodRatings: [],
      showApprovalModal: false,
      approvalData: [],
      orderBy: false,
      order: 'desc',
    };
  }

  UNSAFE_componentWillMount() {
    const { token } = this.props.auth;
    const { questId } = this.props.router.params;
    if (questId) {
      this.props.getCCQuestTiered(questId, token);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { isLoadingCurrent } = this.props.commandCenter.questTiered;
    const { isLoadingNext } = nextProps.commandCenter.questTiered;
    if (isLoadingCurrent === true && isLoadingNext === false) {
      const { data: quest } = this.props.commandCenter.quest;
      const { twitchAccountRequired, youtubeAccountRequired } = quest;
      let platform;
      if (twitchAccountRequired) {
        platform = 'twitch';
      } else if (youtubeAccountRequired) {
        platform = 'youtube';
      } else {
        platform = 'twitch';
      }
      this.setState({
        platform,
      });
    }

    if (this.props.commandCenter.activeUserQuests.isLoadingSpecial === true && nextProps.commandCenter.activeUserQuests.isLoadingSpecial === false) {
      if (nextProps.commandCenter.activeUserQuests.errors.hasError) {
        simpleNotification({
          level: 'error',
          title: 'Error!',
          message: nextProps.commandCenter.activeUserQuests.errors.message,
        });
      } else {
        const { token } = this.props.auth;
        const { questId } = this.props.router.params;
        this.props.getCCQuestTiered(questId, token);
      }
    }

    /* Reputation system */
    // Open modals on response
    const { data: reputationSystem } = nextProps.commandCenter.reputationSystem;
    if (reputationSystem && reputationSystem.modalData) {
      if (reputationSystem.modalData.vodApproved) {
        this.setState({
         // qualityModalOpen: true,
          modalUser: reputationSystem.modalData.user,
          questReqId: reputationSystem.modalData.questReqId,
        });
      }

      if (reputationSystem.modalData.requirementsCompleted) {
        this.setState({
          //congenialityModalOpen: true,
          modalUser: reputationSystem.modalData.user,
        });
      }
    }

    // Save updated ratings to state
    if (nextProps.commandCenter.questTiered && nextProps.commandCenter.questTiered.reputationRatings) {
      this.setState({
        reputationRatings: nextProps.commandCenter.questTiered.reputationRatings,
      });
    }

    // Success/error from reputation submission
    if (this.props.commandCenter.reputationSystem.isLoading && !nextProps.commandCenter.reputationSystem.isLoading) {
      if (nextProps.commandCenter.reputationSystem.errors.hasError) {
        simpleNotification({
          level: 'error',
          title: 'Error!',
          message: nextProps.commandCenter.reputationSystem.errors.message,
        });
      } else {
        simpleNotification({
          level: 'success',
          title: 'Reputation info updated.',
          message: 'Your response has been saved.',
        });
      }
    }

    if (this.props.commandCenter.questApprove.isLoading && !nextProps.commandCenter.questApprove.isLoading) {
      if (!nextProps.commandCenter.questApprove.errors.hasError) {
        // console.log('receive server data:', nextProps.commandCenter.questApprove.data)
        const response = nextProps.commandCenter.questApprove.data;
        // console.log("receive success！start fresh data!")
        //获取失败的个数
        const failedCount = response.failCount;
        if (failedCount === 0) {
          //此时都通过没有错误
          simpleNotification({
            level: 'success',
            title: 'All Users Approved',
            message: "All users have been approved successfully.",
          });
        } else {
          this.setState({
            showApprovalModal: true,
            approvalData: response.list
          })
        }
        this.refreshData()
      } else {
        // console.log('receive server data:', nextProps.commandCenter.questApprove.errors)
        simpleNotification({
          level: 'error',
          title: 'Error',
          message: nextProps.commandCenter.questApprove.errors.message,
        });
      }
    }
  }

  refreshData = () => {
    const { token } = this.props.auth;
    const { questId } = this.props.router.params;
    if (questId) {
      this.props.getCCQuestTiered(questId, token);
    }
  }

  reviewRequirementSortChange = (column) => {
    const { reviewRequirementSort } = this.state;
    if (column === reviewRequirementSort.column) {
      if (reviewRequirementSort.direction === 'asc') {
        this.setState({
          reviewRequirementSort: {
            ...this.state.reviewRequirementSort,
            direction: 'desc',
          },
        });
      } else {
        this.setState({
          reviewRequirementSort: {
            ...this.state.reviewRequirementSort,
            direction: 'asc',
          },
        });
      }
    } else {
      this.setState({
        reviewRequirementSort: {
          ...this.state.reviewRequirementSort,
          column,
          direction: 'asc',
        },
      });
    }
  }

  rounding = number => parseFloat(number).toFixed(2);

  padWithZeroes = (number, length) => {
    let myString = number.toString();
    while (myString.length < length) {
      myString = `0${myString}`;
    }
    return myString;
  }

  toggleElementInfo = (elementId) => {
    const { showingElements } = this.state;
    if (showingElements.indexOf(elementId) === -1) {
      this.setState({
        showingElements: [
          ...showingElements,
          elementId,
        ],
      });
    } else {
      this.setState({
        showingElements: [
          ...without(showingElements, elementId),
        ],
      });
    }
  }

  generateLinkRowsHTML = (questTier, quest) => {
    const { showingElements } = this.state;
    const { twitchAccountRequired, youtubeAccountRequired } = quest;
    let platform;
    if (twitchAccountRequired) {
      platform = 'twitch';
    } else if (youtubeAccountRequired) {
      platform = 'youtube';
    } else {
      platform = 'twitch';
    }

    if (questTier.length > 0) {
      return questTier.map((item, index) => {
        let pre = '';
        if (item && item.link && !item.link.toLowerCase().startsWith('http')) {
          pre = 'http://';
        }
        return (
          <div
            key={questTier.username}
            className={classNames('row toggable tooltip', { open: (showingElements.indexOf(item.username) !== -1) })}
          >
            <div className="row-basic">
              <div className="table-cell">{index + 1}</div>
              <div className="table-cell">
                {item.username}
              </div>
              <div className="table-cell">
                {(platform === 'twitch') && (<a
                  href={`https://www.twitch.tv/${item.twitchUsername}/videos?filter=archives&sort=time`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {item.twitchUsername}
                </a>)}
              </div>
              {
                (item.link) &&
                <div className="table-cell"><Link href={`${pre}${item.link.toLowerCase()}`} target="_blank">{item.link}</Link></div>
              }
              {
                (!item.link) &&
                <div className="table-cell" />
              }
            </div>
          </div>
        );
      });
    }
    return (<div className="NoQuestTiered">No one has joined yet.</div>);
  }

  generateRowsReferralHTML = (questSale, quest) => {
    const { showingElements } = this.state;
    let platform;
    const { twitchAccountRequired, youtubeAccountRequired } = quest;
    if (twitchAccountRequired) {
      platform = 'twitch';
    } else if (youtubeAccountRequired) {
      platform = 'youtube';
    } else {
      platform = 'twitch';
    }

    if (questSale.length > 0) {
      return questSale.map((questSaleItem, index) => {
        let saleKey;
        if (platform === 'twitch') {
          saleKey = questSale.twitchUsername;
        } else {
          saleKey = questSale.twitchUsername;
        }

        return (
          <div
            key={saleKey}
            className={classNames('row toggable', { open: (showingElements.indexOf(questSaleItem.twitchUsername) !== -1) })}
          >
            <div className="row-basic">
              <div className="table-cell">{index + 1}</div>
              <div className="table-cell">{questSaleItem.twitchUsername}</div>
              <div className="table-cell">{questSaleItem.username}</div>
              <div className="table-cell">{questSaleItem.numberOfReferrals}</div>
              <div className="table-cell">${this.rounding(questSaleItem.totalSelled / 100)}</div>
              <div className="table-cell">${this.rounding(getReferralBonus(questSaleItem, quest))}</div>
              <div className="table-cell">${this.rounding(questSaleItem.totalEarnings / 100)}</div>
              <div className="table-cell">
                {(quest.twitchAccountRequired && showingElements.indexOf(questSaleItem.twitchUsername) === -1 && questSaleItem.numberOfReferrals > 0) && (
                  <button className="small-btn" onClick={this.toggleElementInfo.bind(this, questSaleItem.twitchUsername)}>Show Details</button>
                )}
                {(quest.twitchAccountRequired && showingElements.indexOf(questSaleItem.twitchUsername) !== -1) && (
                  <button className="small-btn open" onClick={this.toggleElementInfo.bind(this, questSaleItem.twitchUsername)}>Hide Details</button>
                )}
              </div>
            </div>
            <div className="row-details">
              <section className="StreamActivityTable flex-table">
                <div className="row">
                  <div className="table-cell headline">Date</div>
                  <div className="table-cell headline">Status</div>
                  <div className="table-cell headline">Earning</div>
                  <div className="table-cell headline">Price</div>
                </div>
                {this.generateSalesRowsHTML(questSaleItem.purchases)}
              </section>
            </div>
          </div>
        );
      });
    }
    return (<div className="NoQuestSale">No sales on this Quest.</div>);
  }

  generateSalesRowsHTML = (saleActivity) => {
    if (saleActivity.length > 0) {
      return saleActivity.map(saleItem => (
        <div
          key={`-${saleItem.name}`}
          className="row"
        >
          <div className="table-cell">{`${saleItem.month}/${saleItem.dayOfMonth}/${saleItem.year} ${this.padWithZeroes(saleItem.hour, 2)}:${this.padWithZeroes(saleItem.minute, 2)}`}</div>
          <div className="table-cell">Success</div>
          <div className="table-cell">${this.rounding(saleItem.earning / 100)}</div>
          <div className="table-cell">${this.rounding(saleItem.price / 100)}</div>
        </div>
      ));
    }
    return (<div className="NoSale">No Sale.</div>);
  }

  changeTab = (value) => {
    this.setState({
      tab: value,
    });
  }

  getEditTierModal = (quest, users, user) => (
    <EditTier {...this.props} memberTiers={quest.memberTiers} viewerTiers={viewerTiersWithSpacesLeft(quest, users)} averageViewersAtJoin={user.averageViewersAtJoin} user={user} rehydrateCallback={this.props.getCCQuestTiered} locMod="" />
  )

  getEditUserModal = user => (
    <EditModal {...this.props} currentUser={user} rehydrateCallback={this.props.getCCQuestTiered} />
  )

  // Submit vod data and close modal
  handleBrandSafetySubmit = (comments, selected) => {
    // Create update object
    const brandSafety = { comments, selected };
    const { modalUser, questReqId, vodRatings } = this.state;

    let vodRatingObj = vodRatings.length ? vodRatings.filter(v => v.questReqId === questReqId) : {};
    if (vodRatingObj.length > 0) {
      vodRatingObj = vodRatingObj[0];
    }

    const repObj = {
      vodRating: {
        brandSafety,
        quality: vodRatingObj.quality ? vodRatingObj.quality : {},
        questReqId,
      },
    };

    const { token } = this.props.auth;
    const { questId } = this.props.router.params;

    this.props.postReputationRating(questId, modalUser.id, JSON.stringify(repObj), token);

    // Update reputation in state
    const { reputationRatings } = this.state;
    const repIndex = findIndex(reputationRatings, { user: modalUser.id });
    if (repIndex > -1) {
      reputationRatings[repIndex] = repObj.vodRating;
    } else {
      reputationRatings.push(repObj.vodRating);
    }

    this.setState({
      reputationRatings,
    });

    // Close modal
    this.setState({
      brandSafetyModalOpen: false,
    });
  }

  // Save quality response to state and open brand safety modal
  handleQualityModalChange = (comments, rating) => {
    const quality = { comments, rating };
    const { questReqId, vodRatings } = this.state;

    let vodRatingObj = vodRatings.length ? vodRatings.filter(v => v.questReqId === questReqId) : [];
    if (vodRatingObj.length > 0) vodRatingObj = vodRatingObj[0];

    vodRatingObj.quality = quality;
    vodRatingObj.questReqId = questReqId;

    // Brand safety modal opens after quality modal
    this.setState({
      vodRatings: [...vodRatings, vodRatingObj],
      qualityModalOpen: false,
      brandSafetyModalOpen: true,
    });
  }

  // Submit congeniality and close modals
  handleCongenialityModalSubmit = (comments, rating) => {
    // Create update object
    const repObj = {
      congeniality: {
        comments,
        rating,
      },
    };

    const { token } = this.props.auth;
    const { questId } = this.props.router.params;
    const { modalUser } = this.state;

    this.props.postReputationRating(questId, modalUser.id, JSON.stringify(repObj), token);

    // Update reputation in state
    const { reputationRatings } = this.state;
    const repIndex = findIndex(reputationRatings, { user: modalUser.id });
    if (repIndex > -1) {
      reputationRatings[repIndex].congeniality = repObj.congeniality;
    } else {
      reputationRatings.push(repObj.congeniality);
    }

    this.setState({
      reputationRatings,
    });

    // All modals close after congeniality modal
    this.resetReputationModals();
  }

  // Set current user's reputation data in state for response/editing
  setUserData = (userId) => {
    const {
      data: questTieredAll,
      reputationRatings,
    } = this.props.commandCenter.questTiered;

    const user = questTieredAll.filter(u => u.id === userId);
    const reputation = reputationRatings.filter(r => r.user === userId);

    if (user.length) {
      this.setState({
        modalUser: user[0],
      });

      if (reputation.length) {
        const { congeniality, vodRatings } = reputation[0];
        this.setState({
          isEditingReputation: true,
          congeniality,
          vodRatings,
        });
      } else {
        this.setState({ isEditingReputation: false });
      }
    } else {
      this.setState({ isEditingReputation: false });
    }
  }

  openCongenialityModal = (userId) => {
    this.setUserData(userId);
    this.setState({
      congenialityModalOpen: true,
    });
  }

  openVODModals = (userId, questReqId) => {
    this.setUserData(userId);
    this.setState({
      qualityModalOpen: true,
      questReqId,
    });
  }

  // Close all modals and set responses to default empty
  resetReputationModals = () => {
    this.setState({
      qualityModalOpen: false,
      brandSafetyModalOpen: false,
      congenialityModalOpen: false,
      quality: {
        comments: '',
        rating: {
          value: 0,
          label: '',
        },
      },
      vodRatings: [],
    });
  }

  renderSplitMode = (spliters) => {
    return spliters.map((item, index) => (
      <div className="Summary_auto_second_split_item" key={index}>
        <div className="Summary_auto_second_split_item_left">
          <div className="Summary_auto_second_split_item_left_level">
            Tier {index + 1}
          </div>
          <div className="Summary_auto_second_split_item_left_content">
            <div className="Summary_auto_second_split_item_left_content_days">
              Stream for {formatValueWithUnit(item.streamDays, 'day')}
            </div>
            <div className="Summary_auto_second_split_item_left_content_hours">
              minimum {formatValueWithUnit(item.streamHours, 'hour')}
            </div>
          </div>
        </div>
        <div className="Summary_auto_second_split_item_middle">
          <div className="Summary_auto_second_split_item_middle_title">
            Number of Qualifiers
          </div>
          <div className="Summary_auto_second_split_item_middle_content">
            {item.qualifiedUserCount ?? 0}
          </div>
        </div>
        <div className="Summary_auto_second_split_item_right">
          <div className="Summary_auto_second_split_item_right_prize">
            Prize Pool: ${item?.prizePool?.toLocaleString('en-US')}
          </div>
          <div className="Summary_auto_second_split_item_right_maxRevenue">
            Individual Prize Cap: ${item?.maxRevenue?.toLocaleString('en-US')}
          </div>
        </div>
      </div>
    ))
  }

  // Define table headers based on tab; return array of headers
  getTableHeaders = () => {
    const tableHeaders = [
      {
        id: 'index', numeric: false, disablePadding: true, label: 'Rank',
      },
      {
        id: 'username', numeric: false, disablePadding: true, label: 'Noiz Account',
      },
      {
        id: 'twitchUsername', numeric: false, disablePadding: true, label: 'Twitch',
      },
      {
        id: 'validWatchTime', numeric: false, disablePadding: true, label: 'Valid Watch Time',
      },
      {
        id: 'revenue', numeric: false, disablePadding: true, label: 'Revenue',
      },
      {
        id: 'validStreamingDays', numeric: false, disablePadding: true, label: 'Valid Streaming Days',
      },
      {
        id: 'totalStreamingTime', numeric: false, disablePadding: true, label: 'Total Streaming Time',
      },
      {
        id: 'overallScore', numeric: false, disablePadding: true, label: 'Overall Score',
      },
      {
        id: 'rankAmount', numeric: false, disablePadding: true, label: 'Rank Amount',
      }];

    return tableHeaders;
  }

  createSortHandler = property => (event) => {
    this.handleRequestSort(event, property);
    this.setState({
      numPage: 1,
    });
  };

  handleRequestSort = (event, property) => {
    const desc = this.state.order === 'desc' && this.state.orderBy === property;
    this.setState({
      order: desc ? 'asc' : 'desc',
      orderBy: property,
    }, this.getUserQuests);
  }

  // 修改 getRankTableRows 方法中的相关代码
  getRankTableRows = (leaderboard) => {
    return leaderboard.map((item, index) => {
      return (
        <React.Fragment key={item.id || index}>
          <TableRow hover tabIndex={-1} >
            {/* Index */}
            {/* Index - 添加条件判断 */}
            <TableCell align="center">
              {item.overallScore === 0 ? "Not Ranked" : item.rank}
            </TableCell>
            {/* Select */}
            <TableCell align="center">{item.user?.displayName}</TableCell>
            <TableCell align="center">{item.user?.connectedAccounts?.twitch?.username}</TableCell>
            <TableCell align="center" style={{ color: '#47BCD3' }}>{formatMinutesToHoursAndMinutes(item.userQuest?.viewingTime)}</TableCell>
            <TableCell align="center" style={{ color: '#47BCD3' }}>{formatValueWithUnit(item.userQuest?.rewardData, '$')}</TableCell>
            <TableCell align="center" style={{ color: '#47BCD3' }}>{formatValueWithUnit(item.userQuest?.liveStreamingDays, 'day')}</TableCell>
            <TableCell align="center" style={{ color: '#47BCD3' }}>{formatMinutesToHoursAndMinutes(item.userQuest?.liveStreamingTime)}</TableCell>
            <TableCell align="center" style={{ color: '#47BCD3' }}>{item.overallScore}</TableCell>
            <TableCell align="center" style={{ color: '#47BCD3' }}>{formatValueWithUnit(item?.rewardAmount, '$')}</TableCell>
          </TableRow>
        </React.Fragment>
      );
    });
  }

  renderRankMode = (questLeaderboard) => {
    return (
      <Table>
        <TableHead>
          <TableRow>
            {this.getTableHeaders().map(headCell => (
              <TableHeaderCell
                key={headCell.id}
                align="center"
                padding={headCell.disablePadding ? 'none' : 'default'}
                sortDirection="asc"
                style={{ padding: '0px', textAlign: 'center' }}
              >
                <TableSortLabel
                  active={this.state.orderBy === headCell.id}
                  direction={this.state.order}
                  onClick={this.createSortHandler(headCell.id)}
                  IconComponent={ArrowDropDownIcon}
                >
                  {headCell.label}
                  {this.state.orderBy === headCell.id ? (
                    <span style={{ display: 'none' }}>
                      {this.state.order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </span>
                  ) : null}
                </TableSortLabel>
              </TableHeaderCell>))}
          </TableRow>
        </TableHead>
        <TableBody>
          {
            (this.getRankTableRows(questLeaderboard))
          }
        </TableBody>
      </Table>
    )
  }

  render() {
    const {
      commandCenter: {
        quest: { data: quest },
        questTiered: {
          data: questTieredAll,
          dataReferral: questSales,
          dataKeysAvailables: keysAvailables,
          questLeaderboard,
          questSummary,
          isLoading,
        },
        userQuests: { data: userQuests },
      },
      params: { questType },
      router: { params: { gameId } },
    } = this.props;
    const {
      showingElements,
      brandSafetyModalOpen,
      qualityModalOpen,
      congenialityModalOpen,
      modalUser,
      vodRatings,
      congeniality,
      questReqId,
      isEditingReputation,
      reputationRatings,
    } = this.state;

    const {
      twitchAccountRequired,
      youtubeAccountRequired,
    } = quest;

    const streamingQuest = isStreamingQuest(quest);
    const streamingQuestAutoOrSecond = isAutoStreamQuest(quest);//判断是否为视频自动审核任务或者二创任务
    const questRankMode = quest && quest.rankTiers && quest.rankTiers.length > 0;//判断是否为排名任务
    const questSplitMode = quest && quest.splitTiers && quest.splitTiers.length > 0;//判断是否为分成任务

    let peakViewersWithoutManual = 0;
    if (questSummary && questSummary.peakViewersWithoutManual) {
      peakViewersWithoutManual = questSummary.peakViewersWithoutManual;
    }

    // Filter down to approved users only
    const questTiered = questTieredAll.filter(qt => qt.status !== 'leftQuest');

    let vodRating = vodRatings && questReqId ? vodRatings.filter(r => r.questReqId === questReqId) : { brandSafety: '', quality: '' };
    if (vodRating.length) vodRating = vodRating[0];

    let platform;
    if (twitchAccountRequired) {
      platform = 'twitch';
    } else if (youtubeAccountRequired) {
      platform = 'youtube';
    } else {
      platform = 'twitch';
    }

    const soldKeysReferral = sumBy(questSales, 'numberOfReferrals');

    let totalPayableReferral = sumBy(questSales, 'totalEarnings');
    if (quest && questSales.length > 0) {
      for (let i = 0; i < questSales.length; i += 1) {
        totalPayableReferral += (getReferralBonus(questSales[i], quest) * 100);
      }
    }

    const headersReferralCSV = [
      { label: 'Quest Name', key: 'questName' },
      { label: 'Noiz User Name', key: 'noizUsername' },
      ...(platform === 'twitch' ? [{ label: 'Twitch User Name', key: 'twitchUsername' }] : []),
      { label: 'PayPal Email', key: 'paymentEmail' },
      { label: '# Sold', key: 'numberSold' },
      { label: 'Total Sold', key: 'totalSold' },
      { label: 'Earnings', key: 'earnings' },
      { label: 'Processing Fee', key: 'processingFee' },
      { label: 'To Pay', key: 'toPay' },
      { label: 'Expected Payout Date', key: 'expectedPayoutDate' },
    ];

    const dataReferralCSV = [];

    for (let i = 0; i < questSales.length; i += 1) {
      dataReferralCSV.push({
        questName: quest.title,
        noizUsername: questSales[i].user.username,
        ...(platform === 'twitch' ? { twitchUsername: questSales[i].user.connectedAccounts.twitch.username } : {}),
        paymentEmail: questSales[i].user.paymentEmail,
        numberSold: questSales[i].numberOfReferrals,
        totalSold: `${this.rounding((questSales[i].totalSelled) / 100)}`,
        earnings: `${this.rounding((questSales[i].totalEarnings) / 100)}`,
        processingFee: `${this.rounding((paymentVendorProcessingFee(questSales[i].totalEarnings)) / 100)}`,
        toPay: `${this.rounding((questSales[i].totalEarnings - (paymentVendorProcessingFee(questSales[i].totalEarnings))) / 100)}`,
        expectedPayoutDate: (quest && quest.expectedPayoutDate) ? moment(quest.expectedPayoutDate, 'YYYY-MM-DD').format('MMMM DD YYYY').toString() : '',
      });
    }

    let totalMinutesStreamed = 0;
    let totalHoursStreamed = 0;
    let peakCCV = 0;
    let totalSuccesful = 0;
    let totalFails = 0;
    let percentageCompletedQuest = 0;
    let totalEarning = 0;
    let totalProcessingFee = 0;
    let totalToPay = 0;
    let totalMinutesWatched = 0;
    let projectedMinutesWatched = 0;

    const headersCSV = [
      { label: 'Quest Name', key: 'questName' },
      { label: 'Noiz User Name', key: 'noizUsername' },
      ...(platform === 'twitch' ? [{ label: 'Twitch User Name', key: 'twitchUsername' }] : []),
      { label: 'Site Average Viewers', key: 'siteAverageViewers' },
      { label: 'Account Email', key: 'accountEmail' },
      { label: 'PayPal Email', key: 'paymentEmail' },
      { label: 'Country', key: 'country' },
      { label: 'Quest Average Viewers', key: 'questAverageViewers' },
      { label: 'Peak Viewers', key: 'peakViewers' },
      { label: 'Hours Watched', key: 'hoursWatched' },
      { label: 'Total Time Played', key: 'timePlaying' },
      { label: 'Completed', key: 'completed' },
      { label: 'Earnings', key: 'earnings' },
      { label: 'Processing Fee', key: 'processingFee' },
      { label: 'To Pay', key: 'toPay' },
      { label: 'Key', key: 'gameKey' },
      { label: 'Tier', key: 'tier' },
      { label: 'Expected Payout Date', key: 'expectedPayoutDate' },
      { label: 'Link', key: 'link' },
      { label: 'Date Stream Completed', key: 'endDateTime' },
      { label: 'Date Accepted', key: 'dateAccepted' },
    ];

    const today = moment().tz('America/Los_Angeles');
    const hoursOffset = -today.utcOffset() / 60;
    const dataCSV = [];

    const timeIsUp = moment(quest.endDateTime).add(hoursOffset, 'hours').tz('America/Los_Angeles');
    const questEnded = moment.tz('America/Los_Angeles').toDate() > timeIsUp.toDate();

    for (let i = 0; i < questTiered.length; i += 1) {
      // console.log("userQuest is:",userQuest)
      const userQuest = userQuests.filter(uq => uq.user.id === questTiered[i].id);
      const completed = getUserQuestStatus(quest, userQuest[0], questTiered[i].timePlaying) === 'Completed';

      let siteAverageViewers = 0;
      if (platform === 'twitch') {
        siteAverageViewers = getTwitchAverageViewers(questTiered[i].user);
      }

      const averageViewersAtJoin = (questTiered[i].averageViewersAtJoin) ? Math.round(questTiered[i].averageViewersAtJoin) : 0;

      let acceptedDate = null;
      if (questTiered[i].dateAccepted) {
        acceptedDate = questTiered[i].dateAccepted;
      } else {
        acceptedDate = questTiered[i].dateJoined;
      }

      dataCSV.push({
        questName: quest.title,
        noizUsername: questTiered[i].username,
        ...(platform === 'twitch' ? { twitchUsername: questTiered[i].twitchUsername } : {}),
        // twitchUsername: questTiered[i].twitchUsername,
        accountEmail: (questTiered[i].user) ? questTiered[i].user.email : '- - -',
        paymentEmail: (questTiered[i].user) ? questTiered[i].user.paymentEmail : '- - -',
        country: questTiered[i].user.country,
        siteAverageViewers,
        questAverageViewers: (questTiered[i].averageViewers) ? parseInt(questTiered[i].averageViewers, 10) : '0',
        peakViewers: (questTiered[i].peakViewers) ? parseInt(questTiered[i].peakViewers, 10) : '0',
        hoursWatched: (questTiered[i].averageViewers && questTiered[i].timePlaying) ? parseFloat((questTiered[i].averageViewers * questTiered[i].timePlaying) / 60).toFixed(2) : '0',
        timePlaying: questTiered[i].timePlaying,
        completed: completed ? 'completed' : ' - - -',
        earnings: (questTiered[i].timePlaying >= parseInt(quest.requiredStreamTimeMinutes, 10) && questTiered[i].paymentAmount > 0) ? rounding(questTiered[i].paymentAmount) : '0',
        processingFee: (questTiered[i].timePlaying >= parseInt(quest.requiredStreamTimeMinutes, 10) && questTiered[i].paymentAmount > 0) ? rounding(paymentVendorProcessingFee(questTiered[i].paymentAmount)) : '0',
        toPay: (questTiered[i].timePlaying >= parseInt(quest.requiredStreamTimeMinutes, 10) && questTiered[i].paymentAmount > 0) ? rounding(questTiered[i].paymentAmount - (paymentVendorProcessingFee(questTiered[i].paymentAmount))) : '0',
        gameKey: questTiered[i].key,
        tier: (questTiered[i].tier === 'viewer') ? `${questTiered[i].tier} (${averageViewersAtJoin} viewers)` : questTiered[i].tier,
        expectedPayoutDate: (quest && quest.expectedPayoutDate) ? moment(quest.expectedPayoutDate, 'YYYY-MM-DD').format('MMMM DD, YYYY').toString() : '',
        link: (questTiered[i].link) ? questTiered[i].link : '',
        endDateTime: (questTiered[i].endDateTime) ? moment(questTiered[i].endDateTime).tz('America/Los_Angeles').format('MMMM DD, YYYY -  - hh:mm a').toString() : '',
        dateAccepted: (acceptedDate) ? moment(acceptedDate).tz('America/Los_Angeles').format('MMMM DD, YYYY -  - hh:mm a').toString() : '',
      });

      totalMinutesStreamed += questTiered[i].timePlaying;
      totalMinutesWatched += parseFloat(parseFloat((questTiered[i].averageViewers * questTiered[i].timePlaying)).toFixed(2));
      projectedMinutesWatched += (questTiered[i].averageViewersAtJoin || 0) * (quest.requiredStreamTimeMinutes || 0);

      if (questTiered[i].peakViewers && parseInt(questTiered[i].peakViewers, 10) > peakCCV) {
        peakCCV = parseInt(questTiered[i].peakViewers, 10);
      }

      if (completed) {
        totalEarning += questTiered[i].paymentAmount;
        totalProcessingFee += (paymentVendorProcessingFee(questTiered[i].paymentAmount));
        totalToPay += (questTiered[i].paymentAmount - (paymentVendorProcessingFee(questTiered[i].paymentAmount)));
        totalSuccesful += 1;
      } else {
        totalFails = questEnded ? (totalFails + 1) : totalFails;
      }
    }

    totalHoursStreamed = rounding(totalMinutesStreamed / 60);
    percentageCompletedQuest = (totalSuccesful === 0) ? '0' : rounding((totalSuccesful / questTiered.length) * 100);
    totalEarning = rounding(totalEarning);
    totalProcessingFee = rounding(totalProcessingFee);
    totalToPay = rounding(totalToPay);
    projectedMinutesWatched = Math.round(projectedMinutesWatched);

    const memberTiersOpenSpots = [];
    const viewerTiersSpotsOccupied = [];
    let qualifiedUsers = [];
    let qualifiedUsersForTieredAuto = [];

    if (quest.memberTiers && quest.memberTiers.length) {
      for (let i = 0; i < quest.memberTiers.length; i += 1) {
        let found;
        found = find(questTiered, o => {
          const memberIdentity = quest.memberTiers[i].identity.toLowerCase();
          const twitchMatch = o.twitchUsername && o.twitchUsername.toLowerCase() === memberIdentity;
          const usernameMatch = o.username && o.username.toLowerCase() === memberIdentity;
          
          return o.tier === 'member' && (twitchMatch || usernameMatch);
        });

        if (found) {
          memberTiersOpenSpots.push(0);
        } else {
          memberTiersOpenSpots.push(1);
        }
      }
    }

    if (quest.viewerTiers && quest.viewerTiers.length) {
      for (let i = 0; i < quest.viewerTiers.length; i += 1) {
        const found = filter(questTiered, o => o.tier === 'viewer' && o.paymentAmount === quest.viewerTiers[i].paymentAmount);
        viewerTiersSpotsOccupied.push(found.length);
      }
    }

    if (isLoading) {
      return <section className="CCQuestTiered loading"><i className="fa fa-spinner fa-spin fa-3x fa-fw" /></section>;
    }

    let totalPayable = 0;
    let totalPossible = 0;
    let totalReserved = 0;

    if (quest && questTiered && questTiered.length > 0) {
      const requirements = getQuestRequirements(quest, true);
      const numReqs = requirements ? requirements.length : 0;

      questTiered.forEach((item) => {
        const userQuest = userQuests.filter(uq => uq.user.id === item.id);

        if (item.paymentAmount > 0) {
          totalReserved += item.paymentAmount;

          if (item.timePlaying >= parseInt(quest.requiredStreamTimeMinutes, 10)) {
            totalPossible += item.paymentAmount;

            if (userQuest[0] && userQuest[0].requirements) {
              const numCompletedReqs = getNumApprovedRequirements(quest, userQuest[0]);
              if (numCompletedReqs >= numReqs) {
                totalPayable += item.paymentAmount;
              }
            }
          }
        }
      });
    }

    const containsLinks = filter(questTiered, o => (o.link)).length;

    let totalRequirementsNotCompleted = 0;

    const totalToApprove = [];
    const totalToReject = [];

    const approvalRequired = true;
    const questRequirements = getQuestRequirements(quest, approvalRequired);

    if (userQuests && userQuests.length > 0) {

      questTieredAll.forEach((user) => {
        const userQuest = find(userQuests, o => (o.user.id === user.id));
        user.completionStatus = getCompletionStatus(quest, userQuest, user.timePlaying);
        if (userQuest.chatDetails) { user.chatDetails = userQuest.chatDetails; }
      });
      qualifiedUsers = questTieredAll.filter(user => user.completionStatus.status === 'C').filter(user => user.paymentAmount > 0)

      userQuests.forEach((userQuest) => {
        if (userQuest && (userQuest.status === 'approved' || userQuest.status === 'normal') && questRequirements && questRequirements.length > 0) {
          const userQuestRequirements = userQuest.requirements || [];

          const pendingRequirements = [];

          questRequirements.forEach(({ id: questReqId }) => {
            const foundPendingReq = userQuestRequirements.find(r => r.questReqId === questReqId && r.status === 'to-review');
            if (foundPendingReq) { pendingRequirements.push(foundPendingReq); }
          });

          if (pendingRequirements.length) {
            totalRequirementsNotCompleted += pendingRequirements.length;

            pendingRequirements.forEach((x) => {
              totalToApprove.push({
                userId: userQuest.user.id, title: x.title, type: x.type, status: 'to-review', resolution: true, subtype: x.subtype,
              });
              totalToReject.push({
                userId: userQuest.user.id, title: x.title, type: x.type, status: 'to-review', resolution: true, subtype: x.subtype,
              });
            });
          }
        }
      });
    }

    if (streamingQuestAutoOrSecond) {
      //自动审核和二创任务独立逻辑
      const questStatus = getTaskCompletionStatus(quest)
      if (questStatus.status !== 'P') {
        qualifiedUsersForTieredAuto = questTieredAll
      }
    }

    return (
      <section className="CCQuestTiered">
        <Link to={`/admin/command-center/${gameId}/quests`} activeClassName="active">
          <button className="CCQuests__back--btn">Back</button>
        </Link>

        
        <h2>Title: {quest.title}</h2>

        <div className="Global__tabs">
          <div className={classNames('Global__tab', { active: this.state.tab === 1 })} onClick={this.changeTab.bind(this, 1)} aria-hidden>
            Summary
          </div>
          <div className={classNames('Global__tab', { active: this.state.tab === 2 })} onClick={this.changeTab.bind(this, 2)} aria-hidden>
            {(quest.isReferral === true) ? 'Stream Details' : 'Details'}
          </div>
          {
            (quest.isReferral === true) &&
            <div className={classNames('Global__tab', { active: this.state.tab === 3 })} onClick={this.changeTab.bind(this, 3)} aria-hidden>
              Referral Details
            </div>
          }
          {!streamingQuestAutoOrSecond && (
            <div className={classNames('Global__tab', { active: this.state.tab === 4 })} onClick={this.changeTab.bind(this, 4)} aria-hidden>
              Tier Summary
            </div>
          )}
          {
            (containsLinks > 0) &&
            <div className={classNames('Global__tab', { active: this.state.tab === 5 })} onClick={this.changeTab.bind(this, 5)} aria-hidden>
              Links
            </div>
          }
          {!streamingQuestAutoOrSecond && (
            <div className={classNames('Global__tab', { active: this.state.tab === 6 })} onClick={this.changeTab.bind(this, 6)} aria-hidden>
              Requirements ({totalRequirementsNotCompleted})
            </div>
          )}
          {streamingQuestAutoOrSecond ? (
            <div className={classNames('Global__tab', { active: this.state.tab === 7 })} onClick={this.changeTab.bind(this, 7)} aria-hidden>
              Payments ({qualifiedUsersForTieredAuto.length})
            </div>
          ) : (
            <div className={classNames('Global__tab', { active: this.state.tab === 7 })} onClick={this.changeTab.bind(this, 7)} aria-hidden>
              Payments ({qualifiedUsers.length})
            </div>
          )}

        </div>

        {(this.state.tab === 1) && streamingQuestAutoOrSecond && (
          <section className="Summary_auto_second_content">

            <div className="Summary_auto_second_buttons">
              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                <button className="platform-btn active" data-platform="twitch">
                  <svg className="platform-icon" viewBox="0 0 24 24">
                    <path d="M11.571 4.714h1.715v5.143H11.57zm4.715 0H18v5.143h-1.714zM6 0L1.714 4.286v15.428h5.143V24l4.286-4.286h3.428L22.286 12V0zm14.571 11.143l-3.428 3.428h-3.429l-3 3v-3H6.857V1.714h13.714Z" />
                  </svg>
                  Twitch
                </button>

                {/* <button className="platform-btn active" onClick={() => this.syncRankings()}>
                  同步排行榜
                </button> */}
              </div>
              <section className="Summary_auto_second_header">
                <div className="Summary_auto_second_header_title">
                  Total Participation Data
                </div>
                <div className="Summary_auto_second_header_content">
                  <section className="Summary_auto_second_header_card">
                    <div className="Summary_auto_second_header_card_title">
                      Total Participants
                    </div>
                    <div className="Summary_auto_second_header_card_content">
                      {quest.totalParticipants ? quest.totalParticipants : 0}
                    </div>
                  </section>
                  <section className="Summary_auto_second_header_card">
                    <div className="Summary_auto_second_header_card_title">
                      Total Valid Watch Time
                    </div>
                    <div className="Summary_auto_second_header_card_content">
                      {quest.totalValidWatchTime ? formatMinutesToHoursAndMinutes(quest.totalValidWatchTime) : 0}
                    </div>
                  </section>
                  <section className="Summary_auto_second_header_card">
                    <div className="Summary_auto_second_header_card_title">
                      Total Revenue
                    </div>
                    <div className="Summary_auto_second_header_card_content">
                      ${quest.totalRevenue ? quest.totalRevenue : 0}
                    </div>
                  </section>
                  <section className="Summary_auto_second_header_card">
                    <div className="Summary_auto_second_header_card_title">
                      Total Streaming Hours
                    </div>
                    <div className="Summary_auto_second_header_card_content">
                      {quest.totalStreamingHours ? formatMinutesToHoursAndMinutes(quest.totalStreamingHours) : 0}
                    </div>
                  </section>
                  {/* <section className="Summary_auto_second_header_card">
                    <div className="Summary_auto_second_header_card_title">
                      Total Valid Streaming Days
                    </div>
                    <div className="Summary_auto_second_header_card_content">
                      {quest.totalValidStreamingDays ? quest.totalValidStreamingDays : 0} days
                    </div>
                  </section> */}
                </div>
              </section>
              {questSplitMode && (
                <section className="Summary_auto_second_header">
                  <div className="Summary_auto_second_header_title">
                    Reward Tier Distribution
                  </div>
                  {this.renderSplitMode(quest.splitTiers)}
                </section>
              )}
              {questRankMode && (
                <section className="Summary_auto_second_header">
                  <div className="Summary_auto_second_header_rank">
                    <div className="Summary_auto_second_header_title">
                      Leaderboard
                    </div>
                    <div className="Summary_auto_second_header_title_left">
                      <div className="Summary_auto_second_header_countdown_title">
                        {getQuestCountdown(quest).title}
                      </div>
                      <div className="Summary_auto_second_header_countdown_content">
                        {getQuestCountdown(quest).content}
                      </div>
                    </div>

                    <div className="Summary_auto_second_header_title_right">
                      <div className="Summary_auto_second_header_prizepool">
                        <div className="rank_header_title">
                          Total Prize Pool
                        </div>
                        <div className="rank_header_content">
                          ${quest.totalLeaderBoardPrize ? quest.totalLeaderBoardPrize : 0}
                        </div>
                      </div>
                      <div className="Summary_auto_second_header_prizepool">
                        <div className="rank_header_title">
                          Distributed
                        </div>
                        <div className="rank_header_content">
                          ${quest.distributedLeaderBoardPrize ? quest.distributedLeaderBoardPrize : 0}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="rank_table">
                    {questLeaderboard && questLeaderboard.length > 0
                      ? this.renderRankMode(questLeaderboard)
                      : (<div className="NoStreamActivity">No users data</div>)
                    }
                  </div>
                </section>
              )}
            </div>
          </section>
        )}

        { /* Tab 1: Summary */}
        {(this.state.tab === 1) &&
          !streamingQuestAutoOrSecond && (<section className="ToggleTable">
            <section className="ToggleTable__body-wrapper">
              <section className="ToggleTable__body">
                <div className="ToggleTable__body-row">
                  <div className="ToggleTable__body-rowItem">
                    <div className="ToggleTable__body-itemTop tooltip">
                      Total Completes <i className="fa fa-info-circle" />
                      <div className="tooltiptext">This is the count of all times a user completed this quest, one time or daily.</div>
                    </div>
                    <div className="ToggleTable__body-itemBot green">
                      {totalSuccesful ? totalSuccesful : 0}
                    </div>
                  </div>
                  <div className="ToggleTable__body-rowItem">
                    <div className="ToggleTable__body-itemTop tooltip">
                      Total In Quest <i className="fa fa-info-circle" />
                      <div className="tooltiptext">This is the number of users in this quest.</div>
                    </div>
                    <div className="ToggleTable__body-itemBot">
                      {questTiered ? questTiered.length : 0}
                    </div>
                  </div>
                  <div className="ToggleTable__body-rowItem">
                    <div className="ToggleTable__body-itemTop tooltip">
                      Completion Rate <i className="fa fa-info-circle" />
                      <div className="tooltiptext">Percentage of users that completed this quest.</div>
                    </div>
                    <div className="ToggleTable__body-itemBot green">
                      {(percentageCompletedQuest) || '0'}%
                    </div>
                  </div>
                </div>
                <div className="ToggleTable__body-row" style={{ display: (quest.paymentAmount > 0) ? 'flex' : 'none' }}>
                  <div className="ToggleTable__body-rowItem">
                    <div className="ToggleTable__body-itemTop tooltip">
                      Total Payouts <i className="fa fa-info-circle" />
                      <div className="tooltiptext">To-date cumulative earnings for all successful quest users.</div>
                    </div>
                    <div className="ToggleTable__body-itemBot">
                      ${totalEarning}
                    </div>
                  </div>
                  <div className="ToggleTable__body-rowItem">
                    <div className="ToggleTable__body-itemTop tooltip">
                      Total Processing Fee <i className="fa fa-info-circle" />
                      <div className="tooltiptext">3% of total payouts.</div>
                    </div>
                    <div className="ToggleTable__body-itemBot">
                      ${totalProcessingFee}
                    </div>
                  </div>
                  <div className="ToggleTable__body-rowItem">
                    <div className="ToggleTable__body-itemTop tooltip">
                      Total to Pay <i className="fa fa-info-circle" />
                      <div className="tooltiptext">Total Payouts - Total Processing Fee.</div>
                    </div>
                    <div className="ToggleTable__body-itemBot">
                      ${totalToPay}
                    </div>
                  </div>
                </div>
                <div className="ToggleTable__body-row" style={{ display: (quest.isReferral) ? 'flex' : 'none' }}>
                  <div className="ToggleTable__body-rowItem">
                    <div className="ToggleTable__body-itemTop tooltip">
                      Available Keys <i className="fa fa-info-circle" />
                      <div className="tooltiptext">How many keys are still available for sale.</div>
                    </div>
                    <div className="ToggleTable__body-itemBot">
                      {keysAvailables}
                    </div>
                  </div>
                  <div className="ToggleTable__body-rowItem">
                    <div className="ToggleTable__body-itemTop tooltip">
                      Sold Keys <i className="fa fa-info-circle" />
                      <div className="tooltiptext">How many keys have been sold during this quest.</div>
                    </div>
                    <div className="ToggleTable__body-itemBot">
                      {soldKeysReferral}
                    </div>
                  </div>
                  <div className="ToggleTable__body-rowItem">
                    <div className="ToggleTable__body-itemTop tooltip">
                      Total Payable <i className="fa fa-info-circle" />
                      <div className="tooltiptext">How much is payable to users from referral sales.</div>
                    </div>
                    <div className="ToggleTable__body-itemBot">
                      ${this.padWithZeroes(totalPayableReferral / 100)}
                    </div>
                  </div>
                </div>

                <div className="ToggleTable__body-row">
                  <div className="ToggleTable__body-rowItem">
                    <div className="ToggleTable__body-itemTop tooltip">
                      Total Payable <i className="fa fa-info-circle" />
                      <div className="tooltiptext">Sum of payment amounts for all participants who have met the stream time requirement & have all requirements submitted and approved.</div>
                    </div>
                    <div className="ToggleTable__body-itemBot">
                      ${totalPayable ? totalPayable.toFixed(2) : 0}
                    </div>
                  </div>
                  <div className="ToggleTable__body-rowItem">
                    <div className="ToggleTable__body-itemTop tooltip">
                      Total Possible <i className="fa fa-info-circle" />
                      <div className="tooltiptext">Sum of payment amounts for all participants who have met the stream time requirement.</div>
                    </div>
                    <div className="ToggleTable__body-itemBot">
                      ${totalPossible ? totalPossible.toFixed(2) : 0}
                    </div>
                  </div>
                  <div className="ToggleTable__body-rowItem">
                    <div className="ToggleTable__body-itemTop tooltip">
                      Total Reserved <i className="fa fa-info-circle" />
                      <div className="tooltiptext">Sum of all approved users' tier payments.</div>
                    </div>
                    <div className="ToggleTable__body-itemBot">
                      ${totalReserved ? totalReserved.toFixed(2) : 0}
                    </div>
                  </div>
                </div>
                {/* {streamingQuest &&
                  <div className="ToggleTable__body-row">
                    <div className="ToggleTable__body-rowItem divider">
                    </div>
                  </div>
                }
                {streamingQuest && <div className="ToggleTable__body-row">
                  <div className="ToggleTable__body-rowItem">
                    <div className="ToggleTable__body-itemTop tooltip">
                      Total Chatters <i className="fa fa-info-circle" />
                      <div className="tooltiptext">Number of Unique Chatters</div>
                    </div>
                    <div className="ToggleTable__body-itemBot">
                      {(quest.chatDetails && quest.chatDetails.totalChatters) || '---'}
                    </div>
                  </div>
                  <div className="ToggleTable__body-rowItem">
                    <div className="ToggleTable__body-itemTop tooltip">
                      Total Messages <i className="fa fa-info-circle" />
                      <div className="tooltiptext">Number of Messages</div>
                    </div>
                    <div className="ToggleTable__body-itemBot">
                      {(quest.chatDetails && quest.chatDetails.totalMessages) || '---'}
                    </div>
                  </div>
                  <div className="ToggleTable__body-rowItem">
                    <div className="ToggleTable__body-itemTop tooltip">
                      Average Messages Per Minute <i className="fa fa-info-circle" />
                      <div className="tooltiptext">Average Number of Messages Per Minute of Quest Duration</div>
                    </div>
                    <div className="ToggleTable__body-itemBot">
                      {(quest.chatDetails && (Math.round((quest.chatDetails.avgMessagesPerMinute + Number.EPSILON) * 100) / 100)) || '---'}
                    </div>
                  </div>
                </div>} */}
                {streamingQuest &&
                  <div className="ToggleTable__body-row">
                    <div className="ToggleTable__body-rowItem divider">
                    </div>
                  </div>
                }
                {streamingQuest &&
                  <div className="ToggleTable__body-row">
                    <div className="ToggleTable__body-rowItem">
                      <div className="ToggleTable__body-itemTop tooltip">
                        Total Hours Streamed <i className="fa fa-info-circle" />
                        <div className="tooltiptext">The sum of all hours streamed by all participants.</div>
                      </div>
                      <div className="ToggleTable__body-itemBot">
                        {totalHoursStreamed}
                      </div>
                    </div>
                    <div className="ToggleTable__body-rowItem">
                      <div className="ToggleTable__body-itemTop tooltip">
                        Projected Total Minutes Watched <i className="fa fa-info-circle" />
                        <div className="tooltiptext">Projected total number of minutes to be watched from each participant, calculated from the CCV at join.</div>
                      </div>
                      <div className="ToggleTable__body-itemBot">
                        {projectedMinutesWatched || '0'}
                      </div>
                    </div>
                  </div>
                }
                {streamingQuest &&
                  <div className="ToggleTable__body-row">
                    <div className="ToggleTable__body-rowItem">
                      <div className="ToggleTable__body-itemTop tooltip">
                        Total Minutes Watched <i className="fa fa-info-circle" />
                        <div className="tooltiptext">Number of minutes streamed by each participant, each multipled by their ACCV for this quest, summed up for all participants.</div>
                      </div>
                      <div className="ToggleTable__body-itemBot">
                        {totalMinutesWatched || '0'}
                      </div>
                    </div>
                    {peakViewersWithoutManual ? (
                      <div className="ToggleTable__body-rowItem">
                        <div className="ToggleTable__body-itemTop tooltip">
                          Single Stream / All Streams Peak CCV <i className="fa fa-info-circle" />
                          <div className="tooltiptext">Highest peak CCV for any single stream / Highest peak CCV combined across all streams for this quest.</div>
                        </div>
                        <div className="ToggleTable__body-itemBot">
                          {`${peakCCV || '0'} / ${peakViewersWithoutManual || '0'}`}
                        </div>
                      </div>
                    ) : (
                      <div className="ToggleTable__body-rowItem">
                        <div className="ToggleTable__body-itemTop tooltip">
                          Single Stream <i className="fa fa-info-circle" />
                          <div className="tooltiptext">Highest peak CCV for any single stream as part of this quest.</div>
                        </div>
                        <div className="ToggleTable__body-itemBot">
                          {peakCCV || '0'}
                        </div>
                      </div>
                    )
                    }
                  </div>
                }
              </section>
            </section>
          </section>)
        }

        { /* Tab 2: Details */}
        {(this.state.tab === 2) && !streamingQuestAutoOrSecond &&
          <QuestDetailsTable
            getEditTierModal={this.getEditTierModal}
            getEditUserModal={this.getEditUserModal}
            auth={this.props.auth}
            quest={quest}
            users={questTieredAll}
            questType={questType}
            userQuests={userQuests}
            postCCRequirement={this.props.postCCRequirement}
            openCongenialityModal={this.openCongenialityModal}
            openVODModals={this.openVODModals}
            reputationRatings={reputationRatings}
          />
        }
        {(this.state.tab === 2) && streamingQuestAutoOrSecond &&
          <QuestDetailsTableNew
            getEditTierModal={this.getEditTierModal}
            getEditUserModal={this.getEditUserModal}
            auth={this.props.auth}
            quest={quest}
            users={questTieredAll}
            questType={questType}
            userQuests={userQuests}
            leaderboard={questLeaderboard}
            postCCRequirement={this.props.postCCRequirement}
            openCongenialityModal={this.openCongenialityModal}
            openVODModals={this.openVODModals}
            reputationRatings={reputationRatings}
          />
        }
        { /* Tab 3: Tiers Summary */}
        {
          (this.state.tab === 3 && !streamingQuestAutoOrSecond)
          &&
          <section className="ToggleTable">
            <section className="ToggleTable__header">
              <h3 className="ToggleTable__header-title">Quest Users: <span>{questSales.length}</span></h3>
              <CSVLink data={dataReferralCSV} headers={headersReferralCSV} filename={(quest && quest.game) ? `${quest.game.name} - ${quest.title}.csv` : 'report.csv'}>
                <i className="fa fa-download" />
              </CSVLink>
            </section>
            <section className="ToggleTable__table-wrapper">
              <section className="ToggleTable__table flex-table">
                <div className="row">
                  <div className="table-cell headline">#</div>
                  <div className="table-cell headline">{platform === 'twitch' ? 'Twitch' : ''}</div>
                  <div className="table-cell headline">Noiz</div>
                  <div className="table-cell headline">No. Keys Sold</div>
                  <div className="table-cell headline">Total Sold</div>
                  <div className="table-cell headline">Bonus</div>
                  <div className="table-cell headline">Total Earnings</div>
                  <div className="table-cell headline">Actions</div>
                </div>
                {this.generateRowsReferralHTML(questSales, quest)}
              </section>
            </section>
          </section>
        }
        {
          (this.state.tab === 4 && !streamingQuestAutoOrSecond)
          &&
          <section className="ToggleTable">
            <section className="ToggleTable__table-wrapper">
              <section className="ToggleTable__table flex-table">
                <div className="ToggleTable__table--section" style={{ display: (quest.memberTiers && quest.memberTiers.length !== 0) ? 'block' : 'none' }}>
                  <div className="ToggleTable__table--title">
                    Member Tiers
                  </div>
                  <div className="row">
                    <div className="table-cell headline" style={{ maxWidth: 'inherit' }}>Twitch Username</div>
                    <div className="table-cell headline" style={{ fontWeight: 'normal' }}>Payment Amount</div>
                    <div className="table-cell headline">Open Spots Left</div>
                  </div>
                  {
                    quest.memberTiers &&
                    quest.memberTiers.map((mt, mtIndex) => (
                      <div key={mt.paymentAmount} className="row">
                        <div className="table-cell" style={{ maxWidth: 'inherit' }}>{mt.identity}</div>
                        <div className="table-cell" style={{ fontWeight: 'normal' }}>${mt.paymentAmount}</div>
                        <div className="table-cell">{(memberTiersOpenSpots[mtIndex] <= 0) ? 'FULL' : memberTiersOpenSpots[mtIndex]}</div>
                      </div>
                    ))
                  }
                </div>
                <div className="ToggleTable__table--section" style={{ display: (quest.viewerTiers && quest.viewerTiers.length !== 0) ? 'block' : 'none' }}>
                  <div className="ToggleTable__table--title">
                    {streamingQuest ? 'Viewer Tiers' : 'Default Payment'}
                  </div>
                  <div className="row">
                    <div className="table-cell headline" style={{ maxWidth: 'inherit' }}>Payment Amount</div>
                    {streamingQuest && <div className="table-cell headline" style={{ fontWeight: 'normal' }}>Minimum Viewers</div>}
                    <div className="table-cell headline">Open Spots Left</div>
                    <div className="table-cell headline" />
                  </div>
                  {
                    quest.viewerTiers &&
                    quest.viewerTiers.map((vt, vtIndex) => {
                      const openSpots = vt.spotsAvailable - viewerTiersSpotsOccupied[vtIndex];
                      return (
                        <div
                          key={vt.paymentAmount}
                          className={classNames('row toggable', { open: (showingElements.indexOf(vt.paymentAmount) !== -1) })}
                        >
                          <div className="row-basic">
                            <div className="table-cell" style={{ maxWidth: 'inherit' }}>${vt.paymentAmount}</div>
                            {streamingQuest && <div className="table-cell" style={{ fontWeight: 'normal' }}>{vt.minimumAverageViewers}</div>}
                            <div className="table-cell">{(openSpots <= 0) ? 'FULL' : <React.Fragment>{openSpots}/{vt.spotsAvailable}</React.Fragment>}</div>
                            <div className="table-cell last">
                              {
                                (showingElements.indexOf(vt.paymentAmount) === -1)
                                &&
                                (
                                  <button className="small-btn" onClick={this.toggleElementInfo.bind(this, vt.paymentAmount)}>Show Details</button>
                                )
                              }
                              {
                                (showingElements.indexOf(vt.paymentAmount) !== -1)
                                &&
                                (
                                  <button className="small-btn open" onClick={this.toggleElementInfo.bind(this, vt.paymentAmount)}>Hide Details</button>
                                )
                              }
                            </div>
                          </div>
                          <div className="row-details">
                            <section className="flex-table">
                              <div className="row">
                                <div className="table-cell headline">Username</div>
                                {streamingQuest && <div className="table-cell headline">{platform === 'twitch' ? 'Twitch' : ''} Username</div>}
                                {streamingQuest && <div className="table-cell headline">Average Viewers</div>}
                              </div>
                              {
                                (questTiered.length > 0) &&
                                questTiered.map((item) => {
                                  const viewerTiers = orderBy(quest.viewerTiers, ['minimumAverageViewers'], ['desc']);
                                  let higherPaymentAmount = 0;
                                  let highlight = <React.Fragment />;
                                  for (let i = 0; i < viewerTiers.length; i += 1) {
                                    if (item.averageViewersAtJoin >= viewerTiers[i].minimumAverageViewers) {
                                      higherPaymentAmount = viewerTiers[i].paymentAmount;
                                      break;
                                    }
                                  }
                                  if (higherPaymentAmount > item.paymentAmount) {
                                    highlight =
                                      (
                                        <div className="tooltip">
                                          <i className="fa fa-info-circle" />
                                          <div className="tooltiptext nodecoration">This user qualifies for a higher tier.</div>
                                        </div>
                                      );
                                  }
                                  if (item.paymentAmount === vt.paymentAmount) {
                                    return (
                                      <div
                                        key={item.id}
                                        className="row"
                                      >
                                        <div className="table-cell">{item.username}</div>
                                        {streamingQuest && <div className="table-cell">
                                          {platform === 'twitch' ? item.twitchUsername : ''}
                                        </div>}
                                        {streamingQuest && <div className="table-cell">{(item.averageViewersAtJoin) ? Math.round(item.averageViewersAtJoin) : '0'}{highlight}</div>}
                                      </div>
                                    );
                                  }
                                  return <React.Fragment />;
                                })
                              }
                              {
                                (questTiered.length <= 0) &&
                                (<div className="NoStreamActivity">No users have joined this quest</div>)
                              }
                            </section>
                          </div>
                        </div>
                      );
                    })
                  }
                </div>
              </section>
            </section>
          </section>
        }
        {
          (this.state.tab === 5)
          &&
          <section className="ToggleTable">
            <section className="ToggleTable__header">
              <h3 className="ToggleTable__header-title">Users: <span>{questTiered.length}</span></h3>
              <CSVLink data={dataCSV} headers={headersCSV} filename={(quest && quest.game) ? `${quest.game.name} - ${quest.title}.csv` : 'report.csv'}>
                <i className="fa fa-download" />
              </CSVLink>
            </section>
            <section className="ToggleTable__table-wrapper">
              <section className="ToggleTable__table flex-table">
                <div className="row">
                  <div className="table-cell headline">#</div>
                  <div className="table-cell headline">Noiz</div>
                  <div className="table-cell headline">{platform === 'twitch' ? 'Twitch' : ''}</div>
                  <div className="table-cell headline">Link</div>
                </div>
                {this.generateLinkRowsHTML(questTiered, quest)}
              </section>
            </section>
          </section>
        }
        {
          (this.state.tab === 6)
          &&
          <QuestRequirementsTable
            userQuests={userQuests}
            quest={quest}
            postCCRequirementMultiple={this.props.postCCRequirementMultiple}
            auth={this.props.auth}
          />
        }
        {
          (this.state.tab === 7)
          &&
          !streamingQuestAutoOrSecond &&
          <QuestPaymentsTable
            getEditTierModal={this.getEditTierModal}
            getEditUserModal={this.getEditUserModal}
            auth={this.props.auth}
            quest={quest}
            users={qualifiedUsers}
            questType={questType}
            userQuests={userQuests}
            openCongenialityModal={this.openCongenialityModal}
            postApproveQuest={this.props.postApproveQuest}
            showApprovalModal={this.state.showApprovalModal}
            approvalData={this.state.approvalData}
          />
        }
        {
          (this.state.tab === 7)
          &&
          streamingQuestAutoOrSecond &&
          <QuestPaymentsTableNew
            getEditTierModal={this.getEditTierModal}
            getEditUserModal={this.getEditUserModal}
            auth={this.props.auth}
            users={qualifiedUsersForTieredAuto}
            quest={quest}
            questType={questType}
            userQuests={userQuests}
            openCongenialityModal={this.openCongenialityModal}
            postApproveQuest={this.props.postApproveQuest}
            updateQuest={this.props.getCCQuestTiered}
            showApprovalModal={this.state.showApprovalModal}
            approvalData={this.state.approvalData}
          />
        }
        {qualityModalOpen &&
          <QualityModal
            username={modalUser.username}
            content={vodRating.quality}
            questReqId={questReqId}
            isEditing={isEditingReputation}
            onClose={this.resetReputationModals.bind(this)}
            onNext={this.handleQualityModalChange}
          />
        }
        {brandSafetyModalOpen &&
          <BrandSafetyModal
            username={modalUser.username}
            content={vodRating.brandSafety}
            questReqId={questReqId}
            isEditing={isEditingReputation}
            onClose={this.resetReputationModals.bind(this)}
            onBack={() => { this.setState({ brandSafetyModalOpen: false, qualityModalOpen: true }); }}
            onSubmit={this.handleBrandSafetySubmit}
          />
        }
        { // If all modals open at once, congeniality comes last
          (congenialityModalOpen && !(qualityModalOpen || brandSafetyModalOpen)) &&
          <CongenialityModal
            username={modalUser.username}
            content={congeniality}
            isEditing={isEditingReputation}
            onClose={this.resetReputationModals.bind(this)}
            onSubmit={this.handleCongenialityModalSubmit}
          />
        }
      </section>
    );
  }
}

QuestTiered.propTypes = {

};

export default QuestTiered;
